import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState } from "react";
import { ConvertToKoreanCurrency } from "../Utils/ConvertNumberFormat";

const BarChartComponent = ({ data, maxValue, unit }) => {
  const [tooltip, setTooltip] = useState({ visible: false, value: 0, x: 0 });

  const calculateHeight = (value) => {
    return (value / maxValue) * 135;
  };
  const convertMaxValueToUnit = (maxValue) => {
    if (maxValue >= 1e12) {
      return `백억${unit}`;
    } else if (maxValue >= 1e11) {
      return `십억${unit}`;
    } else if (maxValue >= 1e10) {
      return `억${unit}`;
    } else if (maxValue >= 1e9) {
      return `천만${unit}`;
    } else if (maxValue >= 1e8) {
      return `백만${unit}`;
    } else if (maxValue >= 1e7) {
      return `십만${unit}`;
    } else if (maxValue >= 1e6) {
      return `만${unit}`;
    } else if (maxValue >= 1e5) {
      return `천${unit}`;
    } else if (maxValue >= 1e4) {
      return `백${unit}`;
    } else if (maxValue >= 1e3) {
      return `십${unit}`;
    } else {
      return `${unit}`;
    }
  };

  const handleMouseEnter = (value, index) => {
    setTooltip({ visible: true, value, x: index });
  };

  const handleMouseLeave = () => {
    setTooltip({ visible: false, value: 0, x: 0 });
  };

  return (
    <div className="GraphWidget_Data_Graph">
      <div className="DonutChart_Data_Label_Body">단위 : {convertMaxValueToUnit(maxValue)}</div>
      <div className="BarChart_CT">
        <div className="BarChart_Grid_BG">
          {[100, 80, 60, 40, 20, 0].map((label, idx) => (
            <div key={idx} className="BarChart_GridLine_CT">
              <div className="BarChart_GridLabel">{label}</div>
              <div className="BarChart_GridLine" />
            </div>
          ))}
        </div>

        <div className="BarChart_Chart_CT">
          <div className="BarChart_Chart_Bar_CT">
            {data.map((item, index) => (
              <div
                key={index}
                className="BarChart_Chart_Bar"
                style={{
                  height: `${calculateHeight(item.value)}px`,
                  marginRight: index === data.length - 1 ? "0px" : "24px",
                  backgroundColor: index === data.length - 1 ? "#94BAFD" : "#e7efff",
                }}
                onMouseEnter={() => handleMouseEnter(item.value, index)}
                onMouseLeave={handleMouseLeave}
              />
            ))}
          </div>

          <div className="BarChart_Chart_Label_CT" style={{ marginTop: "8px" }}>
            {data.map((item, index) => (
              <div
                key={index}
                className="BarChart_Chart_Label"
                style={{
                  marginRight: index === data.length - 1 ? "0px" : "24px",
                }}
              >
                {item.label}
              </div>
            ))}
          </div>

          {tooltip.visible && (
            <div
              className="BarChart_Tooltip"
              style={{
                position: "absolute",
                bottom: `${calculateHeight(tooltip.value) + 12}px`,
                // left: `${tooltip.x * 50 + (6 - data.length) * 25 + ((6 - data.length) * 1 + 64)}px`,
                left: `${tooltip.x * 50 + 64}px`,
              }}
            >
              {ConvertToKoreanCurrency(tooltip.value, unit)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BarChartComponent;
