import "../GlobalStyle.css";
import "../PCStyle.css";

import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

const Loading = () => {
  return (
    <div className="Modal_BG">
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
        <PulseLoader color="#94BAFD" size={10} margin={4} loading={true} speedMultiplier={1} />
      </div>
    </div>
  );
};

export default Loading;
