const CertedPlusLogo = () => {
  return (
    <svg width="81" height="29" viewBox="0 0 162 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M122.703 57.8117C117.333 57.8117 113.132 53.5847 113.132 45.7957C113.132 37.8137 117.506 33.8227 122.703 33.8227C126.645 33.8227 128.529 36.14 129.373 38.0283H129.59V26.3556H136V57.4255H129.676V53.6919H129.373C128.485 55.6231 126.536 57.8117 122.703 57.8117ZM119.715 45.7528C119.693 49.8726 121.447 52.7264 124.696 52.7478C127.901 52.7264 129.72 49.9584 129.72 45.7528C129.72 41.5687 127.922 38.8865 124.696 38.8865C121.426 38.8865 119.693 41.6974 119.715 45.7528Z"
        fill="#3769FC"
      />
      <path
        d="M100.223 57.8992C93.012 57.8777 88.5944 53.2645 88.616 45.8832C88.5944 38.6951 93.077 33.8243 99.9633 33.8243C106.135 33.8243 111.007 37.6866 111.007 45.6257V47.4281H94.9393C94.9393 50.9471 97.0398 53.1143 100.353 53.1357C102.54 53.1143 104.099 52.1702 104.727 50.7754H110.834C109.925 55.0883 106.027 57.8777 100.223 57.8992ZM94.9393 43.4371H104.944C104.922 40.6048 102.952 38.5664 100.093 38.5878C97.1481 38.5664 95.0692 40.755 94.9393 43.4371Z"
        fill="#3769FC"
      />
      <path
        d="M86.567 34.1258V38.8893H82.1494V50.2616C82.1277 52.1283 83.0589 52.5789 84.3582 52.6219C85.0078 52.6219 86.1339 52.5575 86.9135 52.4931V57.5141C86.2205 57.6214 85.0728 57.7501 83.492 57.7287C78.8578 57.7501 75.6962 55.6902 75.7395 51.1628V38.8893H72.4912V34.1258H75.7395V28.547H82.1494V34.1258H86.567Z"
        fill="#3769FC"
      />
      <path
        d="M56.2881 57.427V34.1246H62.4814V38.1585H62.7413C63.5858 35.3047 65.773 33.7812 68.4149 33.7812C69.0645 33.7812 69.8874 33.8671 70.4505 33.9958V39.6605C69.8441 39.4674 68.6098 39.3172 67.7219 39.3172C64.8202 39.3172 62.6763 41.3127 62.698 44.1665V57.427H56.2881Z"
        fill="#3769FC"
      />
      <path
        d="M42.7319 57.8992C35.5208 57.8777 31.1032 53.2645 31.1248 45.8832C31.1032 38.6951 35.5858 33.8243 42.4721 33.8243C48.6437 33.8243 53.5161 37.6866 53.5161 45.6257V47.4281H37.4481C37.4481 50.9471 39.5486 53.1143 42.8619 53.1357C45.049 53.1143 46.6082 52.1702 47.2362 50.7754H53.3429C52.4334 55.0883 48.5355 57.8777 42.7319 57.8992ZM37.4481 43.4371H47.4527C47.4311 40.6048 45.4605 38.5664 42.602 38.5878C39.6569 38.5664 37.578 40.755 37.4481 43.4371Z"
        fill="#3769FC"
      />
      <path d="M28.9129 46.8972H19.0968V57.3934L28.9129 46.8972Z" fill="#7EE5C3" />
      <path
        d="M21.6221 37.2685H28.2216C27.3532 30.1074 21.8826 26 14.6318 26C6.339 26 0 31.8278 0 41.9995C0 52.1282 6.23046 57.999 14.6318 57.999C16.2505 57.999 17.7418 57.7849 19.0968 57.3934C19.0968 56.8921 19.0968 56.2435 19.0968 55.0347V52.8234C19.0968 51.7325 19.0968 52.676 19.0968 51.4377C19.0968 51.2169 19.0968 51.0544 19.0968 51.0019C17.9224 51.826 16.421 52.2685 14.7186 52.2787C9.92097 52.2572 6.64293 48.709 6.64293 41.9995C6.64293 35.4406 9.87755 31.7418 14.7621 31.7203C18.4309 31.7418 21.1011 33.8277 21.6221 37.2685Z"
        fill="#3769FC"
      />
      <rect x="146" width="6" height="26" fill="#3769FC" />
      <rect x="162" y="10" width="6" height="26" transform="rotate(90 162 10)" fill="#3769FC" />
    </svg>
  );
};

export default CertedPlusLogo;
