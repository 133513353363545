import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";
import { ConvertToKoreanCurrency, AddCommaAmountNumber } from "../Utils/ConvertNumberFormat";

const ListWidget = observer(({ icon, title, button, leftButtonIcon, rightButtonIcon, onClickButton, listLabel, listArrayData, onClickListItem }) => {
  const checkIfLabelExists = (label) => {
    return listLabel.includes(label);
  };
  const isCompanyNameIncluded = checkIfLabelExists("기업명");
  const isBusinessPeriodIncluded = checkIfLabelExists("업력");
  const isInvestmentPhaseIncluded = checkIfLabelExists("투자단계");
  const isEmployeesNumberIncluded = checkIfLabelExists("직원수");
  const isJoinEmployeesIncluded = checkIfLabelExists("입사자");
  const isResignerEmployeesIncluded = checkIfLabelExists("퇴사자");
  const isAverageAnnualSalaryIncluded = checkIfLabelExists("평균연봉");
  const isInvestmentAmountIncluded = checkIfLabelExists("투자액");
  const isAnnualLaborCostsIncluded = checkIfLabelExists("연간인건비");
  const isInvestmentExhaustionIncluded = checkIfLabelExists("투자금소진예상시기");

  return (
    <div className="Widget_CT">
      {title && (
        <>
          <div className="Widget_Title_CT">
            <div className="Widget_Title_Left_Area">
              <div className="Widget_Title_Icon">
                <Icon name={icon} size={"24"} color={"#7A7A7A"} />
              </div>
              <div className="Widget_Title_Copy">{title}</div>
            </div>
            {button && (
              <div className="Widget_Title_Right_Area">
                <button className="Widget_Button_CT" onClick={onClickButton}>
                  {leftButtonIcon && (
                    <div className="Widget_Button_Left_Icon">
                      <Icon name={leftButtonIcon} size={"24"} color={"#AAAAAA"} />
                    </div>
                  )}
                  <div className="Widget_Button_Label">{button}</div>
                  {rightButtonIcon && (
                    <div className="Widget_Button_Right_Icon">
                      <Icon name={rightButtonIcon} size={"16"} color={"#AAAAAA"} />
                    </div>
                  )}
                </button>
              </div>
            )}
          </div>
          <div className="Widget_Divider" />
        </>
      )}
      <div className="ListWidget_List_CT">
        <div className="ListWidget_Label_CT" style={{ marginTop: title ? "16px" : "24px" }}>
          {isCompanyNameIncluded && <div className="List_Label_CompanyName">기업명</div>}
          {isBusinessPeriodIncluded && <div className="List_Label_BusinessPeriod">업력</div>}
          {isInvestmentPhaseIncluded && <div className="List_Label_InvestmentPhase">투자 단계</div>}
          {isEmployeesNumberIncluded && <div className="List_Label_EmployeesNumber">직원수</div>}
          {isJoinEmployeesIncluded && <div className="List_Label_InvestmentPhase">입사자</div>}
          {isResignerEmployeesIncluded && <div className="List_Label_InvestmentPhase">퇴사자</div>}
          {isAverageAnnualSalaryIncluded && <div className="List_Label_AverageAnnualSalary">평균 연봉</div>}
          {isInvestmentAmountIncluded && <div className="List_Label_InvestmentAmount">투자액</div>}
          {isAnnualLaborCostsIncluded && <div className="List_Label_AnnualLaborCosts">연간 인건비</div>}
          {isInvestmentExhaustionIncluded && (
            <div className="List_Label_InvestmentExhaustion" style={{ marginRight: listArrayData.length > 16 ? "8px" : "0px" }}>
              투자금 소진 예상 시기
            </div>
          )}
        </div>
        <div className="List_Label_Divider" />
        {listArrayData?.length > 0 ? (
          <div
            className="ListWidget_Body_Scroll"
            style={{
              overflow: (title && listArrayData.length > 16) || (!title && listArrayData.length > 17) ? "scroll" : null,
              overflowX: (title && listArrayData.length > 16) || (!title && listArrayData.length > 17) ? "hidden" : null,
              height: title ? "680px" : "732px",
            }}
          >
            {listArrayData.map((listItem) => (
              <React.Fragment key={listItem.ID}>
                <button className="ListWidget_List_Button" onClick={() => onClickListItem(listItem)}>
                  <div className="ListWidget_Body_CT">
                    {isCompanyNameIncluded && <div className="List_Body_CompanyName">{listItem?.CompanyName ? listItem.CompanyName : "-"}</div>}
                    {isBusinessPeriodIncluded && (
                      <div className="List_Body_BusinessPeriod">{listItem?.InvesteeBusinessPeriod ? `${listItem.InvesteeBusinessPeriod} 년차` : "-"}</div>
                    )}
                    {isInvestmentPhaseIncluded && <div className="List_Body_InvestmentPhase">{listItem?.InvestmentStage ? listItem.InvestmentStage : "-"}</div>}
                    {isEmployeesNumberIncluded && <div className="List_Body_EmployeesNumber">{listItem?.Employee ? listItem.Employee : "-"}</div>}
                    {isJoinEmployeesIncluded && (
                      <div className="List_Body_InvestmentPhase">{listItem?.InvesteeJoinEmployees ? `${AddCommaAmountNumber(listItem.InvesteeJoinEmployees)} 명` : "-"}</div>
                    )}
                    {isResignerEmployeesIncluded && (
                      <div className="List_Body_InvestmentPhase">
                        {listItem?.InvesteeResignerEmployees ? `${AddCommaAmountNumber(listItem.InvesteeResignerEmployees)} 명` : "-"}
                      </div>
                    )}
                    {isAverageAnnualSalaryIncluded && (
                      <div className="List_Body_AverageAnnualSalary">
                        {listItem?.InvesteeAverageAnnualSalary ? ConvertToKoreanCurrency(listItem.InvesteeAverageAnnualSalary, "원") : "-"}
                      </div>
                    )}
                    {isInvestmentAmountIncluded && (
                      <div className="List_Body_InvestmentAmount">
                        {listItem?.InvesteeInvestmentAmount ? ConvertToKoreanCurrency(listItem.InvesteeInvestmentAmount, "원") : "-"}
                      </div>
                    )}
                    {isAnnualLaborCostsIncluded && (
                      <div className="List_Body_AnnualLaborCosts">
                        {listItem?.InvesteeAnnualLaborCosts ? ConvertToKoreanCurrency(listItem.InvesteeAnnualLaborCosts, "원") : "-"}
                      </div>
                    )}
                    {isInvestmentExhaustionIncluded && (
                      <div className="List_Body_InvestmentExhaustion">{listItem?.InvesteeInvestmentExhaustion ? listItem.InvesteeInvestmentExhaustion : "-"}</div>
                    )}
                  </div>
                  <div className="List_Body_Divider" />
                </button>
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div className="CreateSeal_Info_Copy" style={{ marginTop: "24px", fontSize: "16px" }}>
            피투자사 목록이 없습니다.
          </div>
        )}
      </div>
    </div>
  );
});

export default ListWidget;
