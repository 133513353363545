import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";
import { ConvertToKoreanCurrency, AddCommaAmountNumber, AddDotYYYYMMDD, ConvertKSTFormatDate } from "../Utils/ConvertNumberFormat";
import { LoadComapanyData } from "../Utils/LoadCompanyData";

const DescriptionWidget = observer(
  ({
    icon,
    title,
    button,
    secondButton,
    leftButtonIcon,
    leftSecondButtonIcon,
    rightButtonIcon,
    rightSecondButtonIcon,
    onClickButton,
    onClickSecondButton,
    descriptionLabel,
    item,
    subItem,
    marginTop,
    type,
    paymentFunction,
  }) => {
    const checkIfLabelExists = (label) => {
      return descriptionLabel.includes(label);
    };

    const userData = LoadComapanyData("userData");
    const isIndustryIncluded = checkIfLabelExists("산업");
    const isTypeIncluded = checkIfLabelExists("기업구분");
    const isCapitalIncluded = checkIfLabelExists("자본금");
    const isRepresentativeIncluded = checkIfLabelExists("대표자");
    const isEstablishDateIncluded = checkIfLabelExists("설립일");
    const isEmployeesNumberIncluded = checkIfLabelExists("직원수");
    const isJoinEmployeesIncluded = checkIfLabelExists("입사자");
    const isResignerEmployeesIncluded = checkIfLabelExists("퇴사자");
    const isInvestmentPhaseIncluded = checkIfLabelExists("투자단계");
    const isInvestmentAmountIncluded = checkIfLabelExists("투자액");
    const isAverageAnnualSalaryIncluded = checkIfLabelExists("평균연봉");
    const isAddressIncluded = checkIfLabelExists("주소");
    const isPaymentStatusIncluded = checkIfLabelExists("상태");
    const isPaymentDateIncluded = checkIfLabelExists("결제일");
    const isExpirationDateIncluded = checkIfLabelExists("만료일");
    const isPaymentCardTypeIncluded = checkIfLabelExists("결제카드");
    const isPaymentCardNumberIncluded = checkIfLabelExists("카드번호");
    const isEmailIncluded = checkIfLabelExists("이메일");

    const currentDate = new Date();

    const getLargestTotalBalance = (subItem) => {
      if (!subItem || !Array.isArray(subItem.shareholderReportList) || subItem.shareholderReportList.length === 0) {
        return null;
      }

      const { shareholderReportList } = subItem;
      const largestReport = shareholderReportList.reduce((maxReport, currentReport) => {
        const currentReportYearMonth = parseInt(currentReport.ReportYearMonth, 10);
        if (!maxReport) {
          return currentReport;
        }
        const maxReportYearMonth = parseInt(maxReport.ReportYearMonth, 10);
        return currentReportYearMonth > maxReportYearMonth ? currentReport : maxReport;
      }, null);

      return largestReport?.MonthlyCashFlow?.MonthlyBankBookBalance?.TotalBalance || null;
    };

    const payment = () => {
      paymentFunction();
    };

    return (
      <div className="Widget_CT" style={{ marginTop: marginTop ? marginTop : "0px" }}>
        {title && (
          <>
            <div className="Widget_Title_CT">
              <div className="Widget_Title_Left_Area">
                <div className="Widget_Title_Icon">
                  <Icon name={icon} size={"20"} color={"#7A7A7A"} />
                </div>
                <div className="Widget_Title_Copy">{title}</div>
              </div>
              {button && item && (
                <div className="Widget_Title_Right_Area">
                  <button className="Widget_Button_CT" onClick={onClickButton}>
                    {leftButtonIcon && (
                      <div className="Widget_Button_Left_Icon">
                        <Icon name={leftButtonIcon} size={"20"} color={"#AAAAAA"} />
                      </div>
                    )}
                    <div className="Widget_Button_Label">{button}</div>
                    {rightButtonIcon && (
                      <div className="Widget_Button_Right_Icon">
                        <Icon name={rightButtonIcon} size={"20"} color={"#AAAAAA"} />
                      </div>
                    )}
                  </button>
                  {secondButton && (
                    <>
                      <div className="DescriptionWidget_Button_Vertical_Divider" />
                      <button className="Widget_Button_CT" onClick={onClickSecondButton}>
                        {leftSecondButtonIcon && (
                          <div className="Widget_Button_Left_Icon">
                            <Icon name={leftSecondButtonIcon} size={"20"} color={"#FF7373"} />
                          </div>
                        )}
                        <div className="Widget_Button_Label" style={{ color: "#FF7373" }}>
                          {secondButton}
                        </div>
                        {rightSecondButtonIcon && (
                          <div className="Widget_Button_Right_Icon">
                            <Icon name={rightSecondButtonIcon} size={"20"} color={"#FF7373"} />
                          </div>
                        )}
                      </button>
                    </>
                  )}
                </div>
              )}
              {button && !item && type === "paymentInfomation" && (
                <div className="Widget_Title_Right_Area">
                  <button className="Widget_Button_CT" onClick={payment}>
                    <div className="Widget_Button_Left_Icon">
                      <Icon name={"AddCreditCard"} size={"20"} color={"#3769FC"} />
                    </div>
                    <div className="Widget_Button_Label" style={{ color: "#3769FC" }}>
                      구독 결제
                    </div>
                  </button>
                </div>
              )}
            </div>
            <div className="Widget_Divider" />
          </>
        )}
        {type === "investorProfile" ? (
          <div className="DescriptionWidget_Data_CT">
            <div className="DescriptionWidget_Data_Left">
              {isEmailIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">이메일</div>
                  <div className="DescriptionWidget_Data_Body">{userData.email || "-"}</div>
                </div>
              )}
              {isIndustryIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">산업</div>
                  <div className="DescriptionWidget_Data_Body">{item.InvestorIndustry || "-"}</div>
                </div>
              )}
              {isTypeIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">기업구분</div>
                  <div className="DescriptionWidget_Data_Body">{item.InvestorType || "-"}</div>
                </div>
              )}
              {isCapitalIncluded && (
                <div className="DescriptionWidget_Data_Item" style={{ alignItems: "flex-end" }}>
                  <div className="DescriptionWidget_Data_Label">자본금</div>
                  <div className="DescriptionWidget_Data_Body" style={{ width: "auto" }}>
                    {ConvertToKoreanCurrency(item.InvestorCapital, "원") || "-"}
                  </div>
                  <div className="DescriptionWidget_Data_Detail" style={{ maxWidth: "100px" }}>
                    ({ConvertKSTFormatDate(currentDate)} 기준)
                  </div>
                </div>
              )}
              {isRepresentativeIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">대표자</div>
                  <div className="DescriptionWidget_Data_Body">{item.InvestorRepresentative || "-"}</div>
                </div>
              )}
              {isEstablishDateIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">설립일</div>
                  <div className="DescriptionWidget_Data_Body">{AddDotYYYYMMDD(item.InvestorEstablishDate) || "-"}</div>
                </div>
              )}
            </div>
            <div className="DescriptionWidget_Data_Vertical_Divider" />
            <div className="DescriptionWidget_Data_Right">
              {isEmployeesNumberIncluded && (
                <div className="DescriptionWidget_Data_Item" style={{ alignItems: "flex-end" }}>
                  <div className="DescriptionWidget_Data_Label">직원수</div>
                  <div className="DescriptionWidget_Data_Body" style={{ width: "auto" }}>
                    {`${AddCommaAmountNumber(item.InvestorEmployeesNumber)} 명` || "-"}
                  </div>
                  <div className="DescriptionWidget_Data_Detail" style={{ maxWidth: "100px" }}>
                    ({ConvertKSTFormatDate(currentDate)} 기준)
                  </div>
                </div>
              )}
              {isJoinEmployeesIncluded && (
                <div className="DescriptionWidget_Data_Item" style={{ alignItems: "flex-end" }}>
                  <div className="DescriptionWidget_Data_Label">입사자</div>
                  <div className="DescriptionWidget_Data_Body" style={{ width: "auto" }}>
                    {`${AddCommaAmountNumber(item.InvestorJoinEmployees)} 명` || "-"}
                  </div>
                  <div className="DescriptionWidget_Data_Detail" style={{ maxWidth: "100px" }}>
                    (최근 3 개월)
                  </div>
                </div>
              )}
              {isResignerEmployeesIncluded && (
                <div className="DescriptionWidget_Data_Item" style={{ alignItems: "flex-end" }}>
                  <div className="DescriptionWidget_Data_Label">퇴사자</div>
                  <div className="DescriptionWidget_Data_Body" style={{ width: "auto" }}>
                    {`${AddCommaAmountNumber(item.InvestorResignerEmployees)} 명` || "-"}
                  </div>
                  <div className="DescriptionWidget_Data_Detail" style={{ maxWidth: "100px" }}>
                    (최근 3 개월)
                  </div>
                </div>
              )}
              {isAverageAnnualSalaryIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">평균연봉</div>
                  <div className="DescriptionWidget_Data_Body">{ConvertToKoreanCurrency(item.InvestorAverageAnnualSalary, "원") || "-"}</div>
                </div>
              )}
              {isAddressIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">주소</div>
                  <div className="DescriptionWidget_Data_Body">{`${item.InvestorAddress}, ${item.InvestorDetailAddress}` || "-"}</div>
                </div>
              )}
            </div>
          </div>
        ) : type === "paymentInfomation" ? (
          item ? (
            <div className="DescriptionWidget_Data_CT">
              <div className="DescriptionWidget_Data_Left">
                {isPaymentStatusIncluded && (
                  <div className="DescriptionWidget_Data_Item">
                    <div className="DescriptionWidget_Data_Label">상태</div>
                    <div className="DescriptionWidget_Data_Body">{item.PaymentStatus || "-"}</div>
                  </div>
                )}
                {isPaymentDateIncluded && (
                  <div className="DescriptionWidget_Data_Item">
                    <div className="DescriptionWidget_Data_Label">결제일</div>
                    <div className="DescriptionWidget_Data_Body">{AddDotYYYYMMDD(item.PaymentDate) || "-"}</div>
                  </div>
                )}
                {isExpirationDateIncluded && (
                  <div className="DescriptionWidget_Data_Item">
                    <div className="DescriptionWidget_Data_Label">만료일</div>
                    <div className="DescriptionWidget_Data_Body">{AddDotYYYYMMDD(item.ExpirationDate) || "-"}</div>
                  </div>
                )}
              </div>
              <div className="DescriptionWidget_Data_Vertical_Divider" />
              <div className="DescriptionWidget_Data_Right">
                {isPaymentCardTypeIncluded && (
                  <div className="DescriptionWidget_Data_Item">
                    <div className="DescriptionWidget_Data_Label">결제카드</div>
                    <div className="DescriptionWidget_Data_Body">{item.PaymentCardType || "-"}</div>
                  </div>
                )}

                {isPaymentCardNumberIncluded && (
                  <div className="DescriptionWidget_Data_Item">
                    <div className="DescriptionWidget_Data_Label">카드번호</div>
                    <div className="DescriptionWidget_Data_Body">{item.PaymentCardNumber || "-"}</div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="DescriptionWidget_Data_CT" style={{ justifyContent: "center", paddingTop: "64px", paddingBottom: "64px" }}>
              <div className="DescriptionWidget_NoItem_Copy">결제 정보가 없습니다.</div>
            </div>
          )
        ) : type === "investeeProfile" ? (
          <div className="DescriptionWidget_Data_CT">
            <div className="DescriptionWidget_Data_Left">
              {isIndustryIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">산업</div>
                  <div className="DescriptionWidget_Data_Body">{item?.Sectors || "-"}</div>
                </div>
              )}
              {isTypeIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">기업구분</div>
                  <div className="DescriptionWidget_Data_Body">{item?.CorporateDivision || "-"}</div>
                </div>
              )}
              {isCapitalIncluded && (
                <div className="DescriptionWidget_Data_Item" style={{ alignItems: "flex-end" }}>
                  <div className="DescriptionWidget_Data_Label">자본금</div>
                  {subItem.shareholderReportList.length > 0 ? (
                    <div className="DescriptionWidget_Data_Body" style={{ width: "auto" }}>
                      {ConvertToKoreanCurrency(getLargestTotalBalance(subItem), "원") || "-"}
                    </div>
                  ) : (
                    <div className="DescriptionWidget_Data_Body" style={{ width: "auto" }}>
                      {ConvertToKoreanCurrency(item.Capital, "원") || "-"}
                    </div>
                  )}
                  <div className="DescriptionWidget_Data_Detail" style={{ maxWidth: "100px" }}>
                    ({ConvertKSTFormatDate(currentDate)} 기준)
                  </div>
                </div>
              )}
              {isRepresentativeIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">대표자</div>
                  <div className="DescriptionWidget_Data_Body">{item?.RepresentativeName || "-"}</div>
                </div>
              )}
              {isEstablishDateIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">설립일</div>
                  <div className="DescriptionWidget_Data_Body">{AddDotYYYYMMDD(item?.EstablishmentDate) || "-"}</div>
                </div>
              )}
              {isEmployeesNumberIncluded && (
                <div className="DescriptionWidget_Data_Item" style={{ alignItems: "flex-end" }}>
                  <div className="DescriptionWidget_Data_Label">직원수</div>
                  {subItem?.employeesStatus[2]?.numberOfPeople.length > 0 ? (
                    <div className="DescriptionWidget_Data_Body" style={{ width: "auto" }}>
                      {`${AddCommaAmountNumber(subItem?.employeesStatus[2]?.numberOfPeople)} 명` || "-"}
                    </div>
                  ) : (
                    <div className="DescriptionWidget_Data_Body" style={{ width: "auto" }}>
                      {`${item.Employee}` || "-"}
                    </div>
                  )}

                  <div className="DescriptionWidget_Data_Detail" style={{ maxWidth: "100px" }}>
                    ({ConvertKSTFormatDate(currentDate)} 기준)
                  </div>
                </div>
              )}
            </div>
            <div className="DescriptionWidget_Data_Vertical_Divider" />
            <div className="DescriptionWidget_Data_Right">
              {isJoinEmployeesIncluded && (
                <div className="DescriptionWidget_Data_Item" style={{ alignItems: "flex-end" }}>
                  <div className="DescriptionWidget_Data_Label">입사자</div>
                  <div className="DescriptionWidget_Data_Body" style={{ width: "auto" }}>
                    {`${AddCommaAmountNumber(subItem?.employeesStatus[0]?.numberOfPeople)} 명` || "-"}
                  </div>
                  <div className="DescriptionWidget_Data_Detail" style={{ maxWidth: "100px" }}>
                    (최근 3 개월)
                  </div>
                </div>
              )}
              {isResignerEmployeesIncluded && (
                <div className="DescriptionWidget_Data_Item" style={{ alignItems: "flex-end" }}>
                  <div className="DescriptionWidget_Data_Label">퇴사자</div>
                  <div className="DescriptionWidget_Data_Body" style={{ width: "auto" }}>
                    {`${AddCommaAmountNumber(subItem?.employeesStatus[1]?.numberOfPeople)} 명` || "-"}
                  </div>
                  <div className="DescriptionWidget_Data_Detail" style={{ maxWidth: "100px" }}>
                    (최근 3 개월)
                  </div>
                </div>
              )}
              {isInvestmentPhaseIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">투자단계</div>
                  <div className="DescriptionWidget_Data_Body">{item?.InvestmentStage || "-"}</div>
                </div>
              )}
              {isInvestmentAmountIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">투자액</div>
                  <div className="DescriptionWidget_Data_Body">{ConvertToKoreanCurrency(item?.Investment, "원") || "-"}</div>
                </div>
              )}
              {isAverageAnnualSalaryIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">평균연봉</div>
                  {subItem?.averageAnnualSalary !== 0 ? (
                    <div className="DescriptionWidget_Data_Body">{ConvertToKoreanCurrency(subItem?.averageAnnualSalary, "원") || "-"}</div>
                  ) : (
                    <div className="DescriptionWidget_Data_Body">정보 없음</div>
                  )}
                </div>
              )}
              {isAddressIncluded && (
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">주소</div>
                  <div className="DescriptionWidget_Data_Body">{`${item?.Address}` || "-"}</div>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
);

export default DescriptionWidget;
