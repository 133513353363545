import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";
import BasicButton from "./BasicButton";

const DescriptionModal = observer(({ title, body, onClickClose, label, onClick }) => {
  return (
    <div className="Modal_BG">
      <div className="Modal_CT">
        <div className="Modal_Title_CT">
          <div className="Modal_Title">{title}</div>
          <button className="Modal_Close_Button" onClick={onClickClose}>
            <Icon name={"Close"} size={"24"} color={"#aaaaaa"} />
          </button>
        </div>
        <div className="Widget_Divider" style={{ marginTop: "8px", marginBottom: "12px" }} />
        <div className="Modal_Body_CT">
          <div className="Modal_Body">{body}</div>
        </div>
        {label && <BasicButton label={label} onClick={onClick} marginTop={"48px"} width={"432px"} state={"destroy"} />}
      </div>
    </div>
  );
});

export default DescriptionModal;
