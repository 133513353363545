import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import axios from "../axiosSetting";
import Loading from "../Components/Loading";
import PageTitle from "../Components/PageTitle";
import ListWidget from "../Components/ListWidget";

const WB51InvestorCompanyList = observer(() => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [investeeList, setInvesteeList] = useState();

  const clickListItem = (item) => {
    navigate(`/investorCompanyDetail/${item.ID}`, {
      state: {
        investeeData: item,
      },
    });
  };

  useEffect(() => {
    const getInvesteeList = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get("/investee");

        if (response.data.status === "피투자사 조회 성공") {
          setInvesteeList(response.data.data);
          setIsLoading(false);
        } else {
          alert("피투자사 목록 불러오기를 실패했습니다.\n새로고침을 실행해주세요.");
          setIsLoading(false);
        }
      } catch (error) {
        alert("피투자사 목록 불러오기 통신을 실패했습니다.\n로그아웃 후 다시 로그인해주세요.");
        console.log("Load Investee List Axios Error : ", error);
        setIsLoading(false);
      }
    };
    if (!investeeList) {
      getInvesteeList();
    }
  }, [investeeList]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div className="Screen_CT">
        <div className="Contents_Area_Left_Margin" />
        <div className="Contents_Area_CT">
          <PageTitle icon={"Domain"} title={"피투자사 목록"} />
          <ListWidget listLabel={["기업명", "투자단계", "직원수"]} listArrayData={investeeList} onClickListItem={clickListItem} />
        </div>
        <div className="Contents_Area_Right_Margin" />
      </div>
    </>
  );
});

export default WB51InvestorCompanyList;
