import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import axios from "../axiosSetting";
import Icon from "../Assets/Icons/Icon";
import Loading from "../Components/Loading";
import CertedPlusLogo from "../Assets/Logo/CertedPlusLogo";
import InputShort from "../Components/InputShort";
import BasicButton from "../Components/BasicButton";
import Footer from "../Components/Footer";

const WB11Login = observer(() => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyEmailDone, setCompanyEmailDone] = useState(false);
  const [companyPassword, setCompanyPassword] = useState("");
  const [companyPasswordDone, setCompanyPasswordDone] = useState(false);

  const saveData = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const onClickLogin = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("/member/login", {
        email: companyEmail,
        password: companyPassword,
      });
      if (response.data.status === "비밀번호 일치") {
        saveData("userData", response.data);
        setIsLoading(false);
        navigate("/home");
      } else if (response.data.status === "비밀번호 불일치") {
        alert("비밀번호가 일치하지 않습니다.\n비밀번호를 확인해주세요.");
        setIsLoading(false);
      } else if (response.data.status === "아이디 불일치") {
        alert("해당 아이디로 회원가입된 정보가 없습니다.\n아이디를 확인해주세요.");
        setIsLoading(false);
      } else {
        alert("로그인을 실패했습니다.\n다시 한번 시도해주세요.");
        setIsLoading(false);
      }
    } catch (error) {
      alert("로그인 통신을 실패했습니다.\n다시 한번 시도해주세요.");
      console.log("Login Axios Error : ", error);
      setIsLoading(false);
    }
  };

  const onClickSignin = () => {
    navigate("/signin");
  };

  useEffect(() => {
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    if (isValidEmail(companyEmail)) {
      setCompanyEmailDone(true);
    } else {
      setCompanyEmailDone(false);
    }
  }, [companyEmail]);

  useEffect(() => {
    if (companyPassword.length > 0) {
      setCompanyPasswordDone(true);
    } else {
      setCompanyPasswordDone(false);
    }
  }, [companyPassword]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div className="Login_Screen_CT ">
        <div className="Login_Screen_Contents" style={{ alignItems: "center" }}>
          <CertedPlusLogo />
          <div className="Login_Title">로그인</div>
          <InputShort
            label={"아이디"}
            placeholder={"이메일 형식의 아이디를 입력해주세요."}
            value={companyEmail}
            onChange={setCompanyEmail}
            required
            requiredCondition={companyEmailDone}
          />
          <InputShort
            label={"비밀번호"}
            placeholder={"비밀번호를 입력해주세요."}
            value={companyPassword}
            onChange={setCompanyPassword}
            required
            requiredCondition={companyPasswordDone}
            type={"password"}
          />
          <BasicButton label={"로그인"} onClick={onClickLogin} marginTop={"8px"} marginBottom={"32px"} state={companyEmailDone && companyPasswordDone ? "CTA" : "disable"} />
          <button className="Login_Signin_TextButton" onClick={onClickSignin}>
            <div className="Login_Signin_Text" style={{ marginRight: "4px" }}>
              써티드 플러스 회원이 아니신가요? <span style={{ color: "rgb(55, 105, 252)", fontWeight: "600" }}>회원가입</span>
            </div>
            <Icon name={"ArrowForward"} size={"20"} color={"rgb(55, 105, 252)"} />
          </button>
        </div>
        <Footer />
      </div>
    </>
  );
});

export default WB11Login;
