import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState } from "react";
import { ConvertToKoreanCurrency } from "../Utils/ConvertNumberFormat";

const LineChartComponent = ({ data, maxValue }) => {
  const [tooltip, setTooltip] = useState({ visible: false, value: 0, x: 0 });

  const calculateYPosition = (value) => {
    if (maxValue === 0) {
      return 0; // maxValue가 0일 경우 yPosition도 0으로 설정
    }
    return (value / maxValue) * 135; // 일반적인 경우
  };

  const convertMaxValueToUnit = (maxValue) => {
    if (maxValue >= 1e12) {
      return "백억원";
    } else if (maxValue >= 1e11) {
      return "십억원";
    } else if (maxValue >= 1e10) {
      return "억원";
    } else if (maxValue >= 1e9) {
      return "천만원";
    } else if (maxValue >= 1e8) {
      return "백만원";
    } else if (maxValue >= 1e7) {
      return "십만원";
    } else if (maxValue >= 1e6) {
      return "만원";
    } else if (maxValue >= 1e5) {
      return "천원";
    } else if (maxValue >= 1e4) {
      return "백원";
    } else if (maxValue >= 1e3) {
      return "십원";
    } else {
      return "원";
    }
  };

  const handleMouseEnter = (value, index) => {
    setTooltip({ visible: true, value, x: index });
  };

  const handleMouseLeave = () => {
    setTooltip({ visible: false, value: 0, x: 0 });
  };

  // const calculateXPosition = (index) => {
  //   const spacing = 296 / data.length;
  //   return index * spacing + 15;
  // };

  const calculateXPosition = (index) => {
    const totalSegments = 6; // Always divide the chart into 6 segments
    const segmentWidth = 296 / totalSegments; // Subtract 1 to account for 0-based indexing
    return index * segmentWidth; // Position based on index
  };

  return (
    <div className="GraphWidget_Data_Graph">
      <div className="DonutChart_Data_Label_Body">단위 : {convertMaxValueToUnit(maxValue)}</div>
      <div className="LineChart_CT">
        <div className="LineChart_Grid_BG">
          {/* 그리드 라인 */}
          {[100, 80, 60, 40, 20, 0].map((label, idx) => (
            <div key={idx} className="LineChart_GridLine_CT">
              <div className="LineChart_GridLabel">{label}</div>
              <div className="LineChart_GridLine" />
            </div>
          ))}
        </div>

        <div className="LineChart_Chart_CT">
          <svg className="LineChart_SVG">
            {data.length > 1 && (
              <polyline
                fill="none"
                stroke="#C8DCFF"
                strokeWidth="3"
                points={data
                  .map((item, index) => {
                    // item이 존재하지 않거나 item.value가 undefined 또는 null인 경우 기본값을 0으로 설정
                    const value = item && item.value !== undefined && item.value !== null && typeof item.value === "number" && !isNaN(item.value) ? item.value : 0; // 기본값 0 설정
                    const yPosition = calculateYPosition(value); // yPosition 계산
                    return `${calculateXPosition(index) + 13},${135 - yPosition}`;
                  })
                  .join(" ")}
              />
            )}
            {data.map((item, index) => (
              <circle
                key={index}
                cx={calculateXPosition(index) + 13}
                cy={isNaN(calculateYPosition(item.value)) ? 0 : 135 - calculateYPosition(item.value)} // NaN 값 처리
                r="4"
                fill="#FFFFFF"
                stroke={index === data.length - 1 ? "#3769fc" : "#94BAFD"}
                strokeWidth="2" // 테두리 두께
                onMouseEnter={() => handleMouseEnter(item.value, index)}
                onMouseLeave={handleMouseLeave}
              />
            ))}
          </svg>

          <div className="LineChart_Chart_Label_CT" style={{ marginTop: "8px" }}>
            {data.map((item, index) => (
              <div
                key={index}
                className="LineChart_Chart_Label"
                style={{
                  marginRight: index === data.length - 1 ? "0px" : "24px",
                }}
              >
                {item.label}
              </div>
            ))}
          </div>

          {tooltip.visible && (
            <div
              className="LineChart_Tooltip"
              style={{
                position: "absolute",
                bottom: `${calculateYPosition(tooltip.value) + 12}px`,
                left: `${tooltip.x * 50 + 64}px`,
              }}
            >
              {ConvertToKoreanCurrency(tooltip.value, "원")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LineChartComponent;
