import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";
import BreakdownGroupComponent from "./BreakdownGroupComponent";

const BreakdownGroupWidget = observer(({ icon, title, button, leftButtonIcon, rightButtonIcon, onClickButton, marginTop, data }) => {
  const month = data.ReportYearMonth.slice(4, 6);

  return (
    <div className="Widget_CT" style={{ marginTop: marginTop ? marginTop : "0px" }}>
      {title && (
        <>
          <div className="Widget_Title_CT">
            <div className="Widget_Title_Left_Area">
              <div className="Widget_Title_Icon">
                <Icon name={icon} size={"24"} color={"#7A7A7A"} />
              </div>
              <div className="Widget_Title_Copy">{title}</div>
            </div>
            {button && (
              <div className="Widget_Title_Right_Area">
                <button className="Widget_Button_CT" onClick={onClickButton}>
                  {leftButtonIcon && (
                    <div className="Widget_Button_Left_Icon">
                      <Icon name={leftButtonIcon} size={"16"} color={"#AAAAAA"} />
                    </div>
                  )}
                  <div className="Widget_Button_Label">{button}</div>
                  {rightButtonIcon && (
                    <div className="Widget_Button_Right_Icon">
                      <Icon name={rightButtonIcon} size={"16"} color={"#AAAAAA"} />
                    </div>
                  )}
                </button>
              </div>
            )}
          </div>
          <div className="Widget_Divider" />
        </>
      )}
      <div className="BarChartGroupWidget_Group_CT" style={{ paddingBottom: "32px" }}>
        <div className="BarChartGroupWidget_Group_Graph_Line_CT" style={{ alignItems: "flex-start" }}>
          <BreakdownGroupComponent title={"월말 통장 잔액"} month={month} type={"balance"} data={data.MonthlyCashFlow.MonthlyBankBookBalance} unit={"원"} />
          <div className="BreakdownGroupComponent_Group_Detail_Vertical_Divider" />
          <BreakdownGroupComponent title={"월 수입"} type={"income"} data={data.MonthlyCashFlow.MonthlyIncome} unit={"원"} />
          <div className="BreakdownGroupComponent_Group_Detail_Vertical_Divider" />
          <BreakdownGroupComponent title={"월 지출"} type={"spending"} data={data.MonthlyCashFlow.MonthlySpending} unit={"원"} />
        </div>
      </div>
    </div>
  );
});

export default BreakdownGroupWidget;
