import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState } from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";
import BarChartGroupComponent from "./BarChartGroupComponent";

const BarChartGroupWidget = observer(({ icon, title, button, leftButtonIcon, rightButtonIcon, onClickButton, marginTop, data }) => {
  const [isVisibleFirstGroup, setIsVisibleFirstGroup] = useState(true);
  const [isVisibleSecondGroup, setIsVisibleSecondGroup] = useState(true);

  const onClickExpandFirstGroup = () => {
    setIsVisibleFirstGroup(!isVisibleFirstGroup);
  };

  const onClickExpandSecondGroup = () => {
    setIsVisibleSecondGroup(!isVisibleSecondGroup);
  };

  return (
    <div className="Widget_CT" style={{ marginTop: marginTop ? marginTop : "0px" }}>
      {title && (
        <>
          <div className="Widget_Title_CT">
            <div className="Widget_Title_Left_Area">
              <div className="Widget_Title_Icon">
                <Icon name={icon} size={"24"} color={"#7A7A7A"} />
              </div>
              <div className="Widget_Title_Copy">{title}</div>
            </div>
            {button && (
              <div className="Widget_Title_Right_Area">
                <button className="Widget_Button_CT" onClick={onClickButton}>
                  {leftButtonIcon && (
                    <div className="Widget_Button_Left_Icon">
                      <Icon name={leftButtonIcon} size={"16"} color={"#AAAAAA"} />
                    </div>
                  )}
                  <div className="Widget_Button_Label">{button}</div>
                  {rightButtonIcon && (
                    <div className="Widget_Button_Right_Icon">
                      <Icon name={rightButtonIcon} size={"16"} color={"#AAAAAA"} />
                    </div>
                  )}
                </button>
              </div>
            )}
          </div>
          <div className="Widget_Divider" />
        </>
      )}
      <div className="BarChartGroupWidget_Group_CT">
        <div className="BarChartGroupWidget_Group_Title_CT">
          <div className="BarChartGroupWidget_Group_Title">회원 지표</div>
          <button className="BarChartGroupWidget_Group_Button" onClick={onClickExpandFirstGroup}>
            <Icon name={isVisibleFirstGroup ? "ExpandLess" : "ExpandMore"} size={"24"} color={"#7A7A7A"} />
          </button>
        </div>
        <div className="BarChartGroupWidget_Group_Title_Divider" />
        {isVisibleFirstGroup && (
          <div className="BarChartGroupWidget_Group_Graph_Line_CT">
            <BarChartGroupComponent title={"신규 회원가입"} data={data.MonthlyCustomerIndicators.MembershipIndicators.NewMembershipRegistration} unit={"명"} />
            <BarChartGroupComponent title={"신규 유료 회원 수"} data={data.MonthlyCustomerIndicators.MembershipIndicators.NewPaidMember} unit={"명"} />
            <BarChartGroupComponent title={"누적 유료 회원 수"} data={data.MonthlyCustomerIndicators.MembershipIndicators.AccumulatedPaidMember} unit={"명"} />
          </div>
        )}
      </div>
      <div className="BarChartGroupWidget_Group_CT" style={{ marginTop: "0px" }}>
        <div className="BarChartGroupWidget_Group_Title_CT">
          <div className="BarChartGroupWidget_Group_Title">수익 지표</div>
          <button className="BarChartGroupWidget_Group_Button" onClick={onClickExpandSecondGroup}>
            <Icon name={isVisibleSecondGroup ? "ExpandLess" : "ExpandMore"} size={"24"} color={"#7A7A7A"} />
          </button>
        </div>
        <div className="BarChartGroupWidget_Group_Title_Divider" />
        {isVisibleSecondGroup && (
          <>
            <div className="BarChartGroupWidget_Group_Graph_Line_CT">
              <BarChartGroupComponent title={"이탈 고객 수"} data={data.MonthlyCustomerIndicators.EarningsIndicators.BreakawayCustomer} unit={"명"} />
              <BarChartGroupComponent title={"MRR (월간 반복 매출)"} data={data.MonthlyCustomerIndicators.EarningsIndicators.MonthlyRecurringRevenue} unit={"원"} />
              <BarChartGroupComponent title={"Churn MRR (이탈 MRR)"} data={data.MonthlyCustomerIndicators.EarningsIndicators.ChurnMonthlyRecurringRevenue} unit={"원"} />
            </div>
            <div className="BarChartGroupWidget_Group_Graph_Line_CT" style={{ marginTop: "24px" }}>
              <BarChartGroupComponent title={"Net MRR (순 MRR)"} data={data.MonthlyCustomerIndicators.EarningsIndicators.NetMonthlyRecurringRevenue} unit={"원"} />
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default BarChartGroupWidget;
