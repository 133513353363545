import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { Select } from "react-dropdown-select";
import { useDaumPostcodePopup } from "react-daum-postcode";
import Icon from "../Assets/Icons/Icon";
import PageTitle from "../Components/PageTitle";
import InputShort from "../Components/InputShort";
import BasicButton from "../Components/BasicButton";

const WB72InvestmentCompanyProfileEdit = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state.item;
  const open = useDaumPostcodePopup();

  const [companyName, setCompanyName] = useState(item.InvestorName || "");
  const [companyNameDone, setCompanyNameDone] = useState(false);
  const [capital, setCapital] = useState(item.InvestorCapital || "");
  const [capitalDone, setCapitalDone] = useState(false);
  const [representative, setRepresentative] = useState(item.InvestorRepresentative || "");
  const [representativeDone, setRepresentativeDone] = useState(false);
  const [establishDate, setEstablishDate] = useState(item.InvestorEstablishDate || "");
  const [establishDateDone, setEstablishDateDone] = useState(false);
  const [year, setYear] = useState(establishDate.slice(0, 4) || "");
  const [month, setMonth] = useState(establishDate.slice(4, 6) || "");
  const [day, setDay] = useState(establishDate.slice(6, 8) || "");
  const [address, setAddress] = useState(item.InvestorAddress || "");
  const [addressDone, setAddressDone] = useState(false);
  const [detailAddress, setDetailAddress] = useState(item.InvestorDetailAddress || "");

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setAddress(fullAddress);
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  const issueStockTypeOptions = [
    {
      value: "대기업",
      label: "대기업",
    },
    {
      value: "중견기업",
      label: "중견기업",
    },
    {
      value: "중소기업",
      label: "중소기업",
    },
  ];

  const isNumericString = (str) => {
    return /^\d+$/.test(str);
  };

  useEffect(() => {
    if (companyName.length > 0) {
      setCompanyNameDone(true);
    } else {
      setCompanyNameDone(false);
    }

    if (capital.length > 0 && isNumericString(capital)) {
      setCapitalDone(true);
    } else {
      setCapitalDone(false);
    }

    if (representative.length > 0) {
      setRepresentativeDone(true);
    } else {
      setRepresentativeDone(false);
    }

    if (year.length === 4 && month.length > 0 && day.length > 0) {
      setEstablishDateDone(true);
    } else {
      setEstablishDateDone(false);
    }

    if (address.length > 0) {
      setAddressDone(true);
    } else {
      setAddressDone(false);
    }
  }, [companyName, capital, representative, establishDate, year, month, day, address]);

  const onClickTitleButton = () => {
    const yearStr = String(year);
    const monthStr = String(month).padStart(2, "0");
    const dayStr = String(day).padStart(2, "0");
    const updatedDateValue = `${yearStr}${monthStr}${dayStr}`;
    item.InvestorName = companyName;
    item.InvestorCapital = capital;
    item.InvestorRepresentative = representative;
    item.InvestorEstablishDate = updatedDateValue;
    item.InvestorAddress = address;
    if (detailAddress.length > 0) {
      item.InvestorDetailAddress = detailAddress;
    }
  };

  const onClickBack = () => {
    navigate("/investmentCompanyProfile");
  };

  return (
    <div className="Screen_CT">
      <div className="Contents_Area_Left_Margin" />
      <div className="Contents_Area_CT">
        <PageTitle icon={"ArrowBack"} title={"프로필 수정"} onClick={onClickBack} />
        <div className="Widget_Short_CT">
          <InputShort label={"기업명"} placeholder={"투자사의 기업명을 입력해주세요."} value={companyName} onChange={setCompanyName} required requiredCondition={companyNameDone} />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            <div className="Input_Label_CT">
              <div className="Input_Label">기업구분</div>
              <div className="Input_Label_Required_Dot" style={{ backgroundColor: "#3769FC" }} />
            </div>
            <Select
              options={issueStockTypeOptions}
              onChange={(selectedOption) => (item.InvestorType = selectedOption[0].value)}
              values={issueStockTypeOptions.filter((option) => option.value === item.InvestorType)}
              placeholder={"기업 구분을 선택해주세요."}
              style={{
                width: 320,
                height: 36,
                borderRadius: 8,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "rgb(227, 227, 227)",
                paddingLeft: 8,
                paddingRight: 8,
                fontFamily: "Pretendard",
                fontWeight: 400,
                fontSize: 14,
                color: "rgb(70, 70, 70)",
              }}
            />
          </div>
          <InputShort
            label={"자본금"}
            placeholder={"숫자만 입력해주세요."}
            value={capital}
            onChange={setCapital}
            required
            requiredCondition={capitalDone}
            unit={"원"}
            marginTop={"24px"}
          />
          <InputShort
            label={"대표자명"}
            placeholder={"대표자명을 입력해주세요."}
            value={representative}
            onChange={setRepresentative}
            required
            requiredCondition={representativeDone}
          />

          <InputShort
            label={"설립일"}
            type={"date"}
            value={establishDate}
            onChange={setEstablishDate}
            required
            requiredCondition={establishDateDone}
            year={year}
            setYear={setYear}
            month={month}
            setMonth={setMonth}
            day={day}
            setDay={setDay}
          />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            <div className="Input_Label_CT">
              <div className="Input_Label">주소</div>
              <div className="Input_Label_Required_Dot" style={{ backgroundColor: addressDone ? "#3769FC" : "#FF7373" }} />
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "320px", marginBottom: "8px" }}>
              <input
                className="Input_Short_BG"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder={"주소를 입력해주세요."}
                name={"address"}
                style={{ width: "270px" }}
              />
              <button style={{ margin: "0px", padding: "0px", backgroundColor: "transparent", borderStyle: "none", cursor: "pointer" }} onClick={handleClick}>
                <Icon name={"Search"} size={"24"} color={"#AAAAAA"} />
              </button>
            </div>
            <input
              className="Input_Short_BG"
              value={detailAddress}
              onChange={(e) => setDetailAddress(e.target.value)}
              placeholder={"상세 주소를 입력해주세요."}
              name={"detailAddress"}
            />
          </div>
          <BasicButton
            label={"수정 완료"}
            onClick={onClickTitleButton}
            marginTop={"24px"}
            marginBottom={"32px"}
            state={companyNameDone && capitalDone && representativeDone && establishDateDone && addressDone ? "CTA" : "disable"}
          />
        </div>
      </div>
      <div className="Contents_Area_Right_Margin" />
    </div>
  );
});

export default WB72InvestmentCompanyProfileEdit;
