import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";
import GNBMenuBar from "./GNBMenuBar";
import CertedLogo from "../Assets/Logo/CertedLogo";
// import CertedForCompanyLogo from "../Assets/Logo/CertedForCompanyLogo";
import CertedPlusLogo from "../Assets/Logo/CertedPlusLogo";

const GNB = observer(() => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState("");
  const location = useLocation();
  const landingPageUrl = "https://www.certifie.io";
  const handleCertifie = () => {
    window.open(landingPageUrl, "_blank", "noopener noreferrer");
  };
  const moveToLandingPage = () => {
    window.open(landingPageUrl, "_blank", "noopener noreferrer");
  };

  React.useEffect(() => {
    const path = location.pathname;
    if (path.startsWith("/home")) {
      setCurrentPage("home");
    } else if (path.startsWith("/inviteInvestorCompany")) {
      setCurrentPage("inviteInvestorCompany");
    } else if (path.startsWith("/investorCompany")) {
      setCurrentPage("investorCompanyList");
    } else if (path.startsWith("/investmentCompanyProfile")) {
      setCurrentPage("investmentCompanyProfile");
    } else {
      setCurrentPage("home");
    }
  }, [location]);

  const onClickHome = () => {
    if (currentPage !== "home") {
      navigate("/home");
    }
  };

  const onClickInviteInvestorCompany = () => {
    if (currentPage !== "inviteInvestorCompany") {
      navigate("/inviteInvestorCompany");
    }
  };

  const onClickInvestorCompanyList = () => {
    if (currentPage !== "investorCompanyList") {
      navigate("/investorCompanyList");
    }
  };

  const onClickInvestmentCompanyProfile = () => {
    if (currentPage !== "investmentCompanyProfile") {
      navigate("/investmentCompanyProfile");
    }
  };

  const onClickLogout = () => {
    localStorage.removeItem("userData");
    navigate("/");
  };

  return (
    <div className="GNB_BG_V">
      <div className="GNB_Upper_V">
        <div className="GNB_LogoCT_V">
          <button className="GNB_Logout_Button" onClick={handleCertifie}>
            <div className="GNB_Logo_V">
              <CertedPlusLogo />
            </div>
          </button>
        </div>
        <div className="GNB_MenuBar_CT" style={{ marginTop: 24 }}>
          <GNBMenuBar label={"홈"} currentPage={currentPage} currentPageName={"home"} onClick={onClickHome} />
        </div>
        <div className="GNB_MenuBar_Divider" />
        <div className="GNB_MenuBar_CT" style={{ marginTop: 0 }}>
          <GNBMenuBar label={"피투자사 추가"} currentPage={currentPage} currentPageName={"inviteInvestorCompany"} onClick={onClickInviteInvestorCompany} />
          <GNBMenuBar label={"피투자사 목록"} currentPage={currentPage} currentPageName={"investorCompanyList"} onClick={onClickInvestorCompanyList} />
        </div>
        <div className="GNB_MenuBar_Divider" />
        <div className="GNB_MenuBar_CT" style={{ marginTop: 0 }}>
          <GNBMenuBar label={"프로필"} currentPage={currentPage} currentPageName={"investmentCompanyProfile"} onClick={onClickInvestmentCompanyProfile} />
        </div>
        <div className="GNB_MenuBar_Divider" />
        <div className="GNB_MenuBar_CT" style={{ marginTop: 0 }}>
          <button className="GNB_MenuBar_BG_Off" type="button" onClick={onClickLogout}>
            <div className="GNB_MenuBar_Contents">
              <Icon name={"Logout"} size={"24"} color={"#464646"} />
              <div className="GNB_MenuBar_Label_Off">로그아웃</div>
            </div>
          </button>
        </div>
      </div>
      <div className="GNB_Lower_V">
        <div className="Footer_Contents_CT">
          <button className="Footer_Button" onClick={moveToLandingPage}>
            <div className="Footer_Logo">
              <CertedLogo width={"68"} height={"16"} />
            </div>
          </button>
          <div className="Footer_Texts">
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">상호명 | 주식회사 지디피스튜디오</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">대표자 | 이유</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">사업자 등록번호 | 146-87-02284</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">통신판매업신고번호 | 2023-창원성산-0866</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">주소 | 서울특별시 금천구 가산디지털1로 120 서울디지털산업단지 G밸리창업큐브 603 (08590)</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">이메일 | help@certifie.io</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">전화번호 | 010-6212-0225</div>
            </div>
            <div className="Footer_Text">
              <div className="Footer_Text_Copy">ⓒ 2024 GDP Studio Inc. All rights reserved.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default GNB;
