import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState } from "react";
import { observer } from "mobx-react";
import { AddDotYYYYMMDD } from "../Utils/ConvertNumberFormat";

const TabMenuListWidget = observer(({ marginTop, tabList, firstDataList, onClickListItem, companyData }) => {
  const [firstTab, setFirstTab] = useState(true);
  const [secondTab, setSecondTab] = useState(false);
  const [thirdTab, setThirdTab] = useState(false);

  const formatReportYearMonthDateString = (dateString) => {
    if (dateString.length === 6) {
      const year = dateString.slice(0, 4); // 앞 4자리
      const month = dateString.slice(4, 6); // 뒤 2자리
      return `${year}년 ${month}월`;
    }
    return dateString;
  };

  const onClickFirstTab = () => {
    setFirstTab(true);
    setSecondTab(false);
    setThirdTab(false);
  };

  const onClickSecondTab = () => {
    setFirstTab(false);
    setSecondTab(true);
    setThirdTab(false);
  };

  const onClickThirdTab = () => {
    setFirstTab(false);
    setSecondTab(false);
    setThirdTab(true);
  };

  return (
    <div className="Widget_CT" style={{ marginTop: marginTop ? marginTop : "0px" }}>
      <>
        <div className="Widget_Title_CT">
          <div className="Widget_Title_Left_Area">
            {tabList.length === 1 && (
              <button className="TabMenuListWidget_Title_Tab" onClick={onClickFirstTab}>
                <div className="Widget_Title_Copy" style={{ color: firstTab ? "#464646" : "#AAAAAA" }}>
                  {tabList[0]}
                </div>
              </button>
            )}
            {tabList.length === 2 && (
              <>
                <button className="TabMenuListWidget_Title_Tab" onClick={onClickFirstTab}>
                  <div className="Widget_Title_Copy" style={{ color: firstTab ? "#464646" : "#AAAAAA" }}>
                    {tabList[0]}
                  </div>
                </button>
                <button className="TabMenuListWidget_Title_Tab" onClick={onClickSecondTab}>
                  <div className="Widget_Title_Copy" style={{ color: secondTab ? "#464646" : "#AAAAAA" }}>
                    {tabList[1]}
                  </div>
                </button>
              </>
            )}
            {tabList.length === 3 && (
              <>
                <button className="TabMenuListWidget_Title_Tab" onClick={onClickFirstTab}>
                  <div className="Widget_Title_Copy" style={{ color: firstTab ? "#464646" : "#AAAAAA" }}>
                    {tabList[0]}
                  </div>
                </button>
                <button className="TabMenuListWidget_Title_Tab" onClick={onClickSecondTab}>
                  <div className="Widget_Title_Copy" style={{ color: secondTab ? "#464646" : "#AAAAAA" }}>
                    {tabList[1]}
                  </div>
                </button>
                <button className="TabMenuListWidget_Title_Tab" onClick={onClickThirdTab}>
                  <div className="Widget_Title_Copy" style={{ color: thirdTab ? "#464646" : "#AAAAAA" }}>
                    {tabList[2]}
                  </div>
                </button>
              </>
            )}
          </div>
        </div>
        <div className="Widget_Divider" />
        {firstDataList.length > 0 ? (
          <div className="ListWidget_List_CT">
            <div className="ListWidget_Label_CT">
              <div className="TabMenuList_Label_DocumentName">문서명</div>
              <div className="TabMenuList_Label_ReportYearMonth">주주보고서 시기</div>
              <div className="TabMenuList_Label_IssueDate" style={{ marginRight: firstDataList.length > 11 ? "32px" : "24px" }}>
                작성일
              </div>
            </div>
            <div className="List_Label_Divider" />
            <div
              className="ListWidget_Body_Scroll"
              style={{
                overflow: firstDataList.length > 11 ? "scroll" : null,
                overflowX: firstDataList.length > 11 ? "hidden" : null,
                height: "450px",
              }}
            >
              {firstDataList.map((listItem) => (
                <React.Fragment key={listItem.ID}>
                  <button className="ListWidget_List_Button" onClick={() => onClickListItem(listItem, companyData)}>
                    <div className="ListWidget_Body_CT">
                      <div className="TabMenuList_Body_DocumentName">{listItem?.ReportName ? listItem.ReportName : "-"}</div>
                      <div className="TabMenuList_Body_ReportYearMonth">{listItem?.IssueDate ? formatReportYearMonthDateString(listItem.ReportYearMonth) : "-"}</div>
                      <div className="TabMenuList_Body_IssueDate">{listItem?.IssueDate ? AddDotYYYYMMDD(listItem.IssueDate) : "-"}</div>
                    </div>
                    <div className="List_Body_Divider" />
                  </button>
                </React.Fragment>
              ))}
            </div>
          </div>
        ) : (
          <>
            <div className="CreateSeal_Info_Copy" style={{ marginTop: "24px", fontSize: "16px" }}>
              주주보고서 목록이 없습니다.
            </div>
          </>
        )}
      </>
    </div>
  );
});

export default TabMenuListWidget;
