import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";

const BasicButton = observer(({ icon, label, onClick, marginTop, marginBottom, state, width }) => {
  return (
    <button
      className="BasicButton_CT"
      onClick={state === "disable" ? null : onClick}
      style={{
        marginTop: marginTop ? marginTop : "0px",
        marginBottom: marginBottom ? marginBottom : "0px",
        backgroundColor: state === "disable" ? "#E3E3E3" : state === "destroy" ? "#ff7373" : "#3769fc",
        width: width ? width : "320px",
      }}
    >
      {icon && (
        <div className="BasicButton_Icon">
          <Icon name={icon} size={"24"} color={state === "disable" ? "#AAAAAA" : "#FFFFFF"} />
        </div>
      )}
      <div className="BasicButton_Label" style={{ color: state === "disable" ? "#AAAAAA" : "#FFFFFF" }}>
        {label}
      </div>
    </button>
  );
});

export default BasicButton;
