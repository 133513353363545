import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { observer } from "mobx-react";
import { ConvertToKoreanCurrency } from "../Utils/ConvertNumberFormat";

const BreakdownGroupComponent = observer(({ title, data, type, unit, month }) => {
  return (
    <>
      <div className="BarChartGroupWidget_Group_Graph_CT" style={{ marginTop: "0px" }}>
        <div className="BarChartGroupWidget_Group_Graph_Title_CT">
          <div className="BarChartGroupWidget_Group_Graph_Title">{title}</div>
        </div>
        <div className="BarChartGroupWidget_Group_Graph_Body">
          총 {ConvertToKoreanCurrency(type === "balance" ? data.TotalBalance : type === "income" ? data.TotalIncome : data.TotalSpending, unit)}
        </div>
        <div className="BarChartGroupWidget_Group_Graph_Divider" style={{ marginBottom: "12px" }} />
        {type === "balance" && (
          <>
            <div className="BreakdownGroupComponent_Group_Detail_CT" style={{ marginBottom: "12px" }}>
              <div className="BreakdownGroupComponent_Group_Detail_Label">{month}월 투자금</div>
              <div className="BreakdownGroupComponent_Group_Detail_Value">{ConvertToKoreanCurrency(data.InvestmentFund, unit)}</div>
            </div>
            <div className="BreakdownGroupComponent_Group_Detail_CT" style={{ marginBottom: "0px" }}>
              <div className="BreakdownGroupComponent_Group_Detail_Label">{month}월 지원금</div>
              <div className="BreakdownGroupComponent_Group_Detail_Value">{ConvertToKoreanCurrency(data.SupportFund, unit)}</div>
            </div>
          </>
        )}
        {type === "income" && data.IncomeData.length > 0 ? (
          <>
            {data.IncomeData.map((item, index) => (
              <div key={item.ID} className="BreakdownGroupComponent_Group_Detail_CT" style={{ marginBottom: index === data.length - 1 ? "0px" : "12px" }}>
                <div className="BreakdownGroupComponent_Group_Detail_Label">{item.Title}</div>
                <div className="BreakdownGroupComponent_Group_Detail_Value">{ConvertToKoreanCurrency(item.Income, unit)}</div>
              </div>
            ))}
          </>
        ) : null}
        {type === "spending" && (
          <>
            <div className="BreakdownGroupComponent_Group_Detail_CT" style={{ marginBottom: "12px" }}>
              <div className="BreakdownGroupComponent_Group_Detail_Label">고정비</div>
              <div className="BreakdownGroupComponent_Group_Detail_Value">{ConvertToKoreanCurrency(data.FixedCost, unit)}</div>
            </div>
            <div className="BreakdownGroupComponent_Group_Detail_CT" style={{ marginBottom: "0px" }}>
              <div className="BreakdownGroupComponent_Group_Detail_Label">변동비</div>
              <div className="BreakdownGroupComponent_Group_Detail_Value">{ConvertToKoreanCurrency(data.VariableCost, unit)}</div>
            </div>
          </>
        )}
      </div>
    </>
  );
});

export default BreakdownGroupComponent;
