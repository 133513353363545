import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import axios from "../axiosSetting";
import Loading from "../Components/Loading";
import PageTitle from "../Components/PageTitle";
import InputShort from "../Components/InputShort";
import BasicButton from "../Components/BasicButton";

const WB41InviteInvestorCompany = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [investerCompanyName, setInvesterCompanyName] = useState("");
  const [investerCompanyNameDone, setInvesterCompanyNameDone] = useState(false);
  const [investerCompanyEmail, setInvesterCompanyEmail] = useState("");
  const [investerCompanyEmailDone, setInvesterCompanyEmailDone] = useState(false);

  const onSendConnectEmail = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("/member/assignment", {
        transmissionCompanyName: investerCompanyName,
        transmissionCompanyEmail: investerCompanyEmail,
      });
      if (response.data.status === "메일 전송 성공") {
        setIsLoading(false);
        setInvesterCompanyName("");
        setInvesterCompanyNameDone(false);
        setInvesterCompanyEmail("");
        setInvesterCompanyEmailDone(false);
        alert("피투자사 연동 메일 발송에 성공하였습니다.");
      } else {
        setIsLoading(false);
        alert("피투자사 연동 메일 발송에 실패하였습니다.\n다시 한번 시도해 주세요.");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("Connect Investee ERROR : ", error);
      alert("피투자사 연동 메일 발송 통신에 실패하였습니다.\n다시 한번 시도해 주세요.");
    }
  };

  useEffect(() => {
    if (investerCompanyName.length > 0) {
      setInvesterCompanyNameDone(true);
    } else {
      setInvesterCompanyNameDone(false);
    }
  }, [investerCompanyName]);

  useEffect(() => {
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    if (isValidEmail(investerCompanyEmail)) {
      setInvesterCompanyEmailDone(true);
    } else {
      setInvesterCompanyEmailDone(false);
    }
  }, [investerCompanyEmail]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      <div className="Screen_CT">
        <div className="Contents_Area_Left_Margin" />
        <div className="Contents_Area_CT">
          <PageTitle icon={"AddDomain"} title={"피투자사 추가"} />
          <div className="Widget_Short_CT">
            <InputShort
              label={"기업명"}
              placeholder={"피투자사의 기업명을 입력해주세요."}
              value={investerCompanyName}
              onChange={setInvesterCompanyName}
              required
              requiredCondition={investerCompanyNameDone}
            />
            <InputShort
              label={"기업 담당자 이메일"}
              placeholder={"피투자사의 담당자 이메일 주소를 입력해주세요."}
              value={investerCompanyEmail}
              onChange={setInvesterCompanyEmail}
              required
              requiredCondition={investerCompanyEmailDone}
            />
            <BasicButton
              label={"초대 메일 발송"}
              onClick={onSendConnectEmail}
              marginTop={"24px"}
              marginBottom={"32px"}
              state={investerCompanyNameDone && investerCompanyEmailDone ? "CTA" : "disable"}
            />
          </div>
        </div>
        <div className="Contents_Area_Right_Margin" />
      </div>
    </>
  );
});

export default WB41InviteInvestorCompany;
