import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import Icon from "../Assets/Icons/Icon";

const GNBMenuBar = ({ currentPage, currentPageName, onClick, label }) => {
  return (
    <button className={`GNB_MenuBar_BG_${currentPage === currentPageName ? "On" : "Off"}`} type="button" onClick={onClick}>
      <div className="GNB_MenuBar_Contents">
        <Icon
          name={
            label === "홈"
              ? "Home"
              : label === "알림"
              ? "Notification"
              : label === "피투자사 추가"
              ? "AddDomain"
              : label === "피투자사 목록"
              ? "Domain"
              : label === "프로필"
              ? "Profile"
              : ""
          }
          size={"24"}
          color={currentPage === currentPageName ? "#3769FC" : "#464646"}
        />
        <div className={`GNB_MenuBar_Label_${currentPage === currentPageName ? "On" : "Off"}`}>{label}</div>
      </div>
    </button>
  );
};

export default GNBMenuBar;
