import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { observer } from "mobx-react";
import CertedLogo from "../Assets/Logo/CertedLogo";

const Footer = observer(() => {
  const onClickCertedLogo = () => {
    window.open("https://www.certifie.io", "_blank", "noopener noreferrer");
  };

  return (
    <div className="Footer_CT">
      <div className="Widget_Divider" />
      <div className="Footer_Item_Row_CT">
        <button className="Footer_LogoButton" onClick={onClickCertedLogo}>
          <CertedLogo width={"68"} height={"16"} />
        </button>
        <div className="Footer_Item_CT">
          <div className="Footer_Item_Text" style={{ marginBottom: "4px" }}>
            상호명 | 주식회사 지디피스튜디오
          </div>
          <div className="Footer_Item_Text">대표자 | 이유</div>
        </div>
        <div className="Footer_Item_CT">
          <div className="Footer_Item_Text" style={{ marginBottom: "4px" }}>
            사업자 등록번호 | 146-87-02284
          </div>
          <div className="Footer_Item_Text">통신판매업신고번호 | 2023-창원성산-0866</div>
        </div>
        <div className="Footer_Item_CT">
          <div className="Footer_Item_Text" style={{ marginBottom: "4px" }}>
            이메일 | help@certifie.io
          </div>
          <div className="Footer_Item_Text">전화번호 | 010-6212-0225</div>
        </div>
        <div className="Footer_Item_CT" style={{ alignItems: "flex-end" }}>
          <div className="Footer_Item_Text" style={{ marginBottom: "4px" }}>
            주소 | 서울특별시 금천구 가산디지털1로 120 서울디지털산업단지 G밸리창업큐브 603 (08590){" "}
          </div>
          <div className="Footer_Item_Text">ⓒ 2024 GDP Studio Inc. All rights reserved.</div>
        </div>
      </div>
    </div>
  );
});

export default Footer;
