import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState } from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";
import { ConvertToKoreanCurrency } from "../Utils/ConvertNumberFormat";
import BarChartComponent from "./BarChartComponent";
import DescriptionModal from "./DescriptionModal";

const BarChartGroupComponent = observer(({ title, data, unit }) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const getNextHigherUnit = (data) => {
    const maxValue = Math.max(...data.map((item) => item.value));
    const exponent = Math.floor(Math.log10(maxValue));
    const nextUnit = Math.pow(10, exponent + 1);
    const maxValueStr = maxValue.toString();
    if (
      maxValueStr[0] === "1" &&
      maxValueStr
        .slice(1)
        .split("")
        .every((char) => char === "0")
    ) {
      return maxValue;
    } else {
      return nextUnit;
    }
  };

  const onClickOpenModal = () => {
    setIsVisibleModal(true);
  };

  const onClickCloseModal = () => {
    setIsVisibleModal(false);
  };

  const getDescriptionModalBodyText = () => {
    if (title === "신규 회원가입") {
      return "신규 회원가입 설명글 분문";
    } else if (title === "신규 유료 회원 수") {
      return "신규 유료 회원 수 본문";
    } else if (title === "누적 유료 회원 수") {
      return "누적 유료 회원 수 본문";
    } else if (title === "이탈 고객 수") {
      return "이탈 고객 수 본문";
    } else if (title === "MRR (월간 반복 매출)") {
      return "구독(또는 계약) 중인 고객들로부터 매월 들어오는 돈을 보여주는 지표";
    } else if (title === "Churn MRR (이탈 MRR)") {
      return "Downgrade 고객으로 인한 손실이 있을 경우 추가 반영";
    } else if (title === "Net MRR (순 MRR)") {
      return "월간 전체 구독 비용 + 확장 매출 - 이탈 고객 으로 인한 매출 손실";
    } else {
      return "본문 내용이 없습니다.";
    }
  };

  return (
    <>
      {isVisibleModal && <DescriptionModal title={title} body={getDescriptionModalBodyText()} onClickClose={onClickCloseModal} />}
      <div className="BarChartGroupWidget_Group_Graph_CT">
        <div className="BarChartGroupWidget_Group_Graph_Title_CT">
          <div className="BarChartGroupWidget_Group_Graph_Title">{title}</div>
          {(title === "MRR (월간 반복 매출)" || title === "Churn MRR (이탈 MRR)" || title === "Net MRR (순 MRR)") && (
            <button className="BarChartGroupWidget_Group_Button" style={{ marginLeft: "4px" }} onClick={onClickOpenModal}>
              <Icon name={"InfoIcon"} size={"16"} color={"#AAAAAA"} />
            </button>
          )}
        </div>
        <div className="BarChartGroupWidget_Group_Graph_Body">{ConvertToKoreanCurrency(data[data.length - 1].value, unit)}</div>
        <div className="BarChartGroupWidget_Group_Graph_Divider" />
        <BarChartComponent data={data} maxValue={getNextHigherUnit(data)} unit={unit} />
      </div>
    </>
  );
});

export default BarChartGroupComponent;
