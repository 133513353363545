import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState } from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";

const NoteGroupWidget = observer(({ title, icon, data, marginTop }) => {
  const [isVisibleFirstGroup, setIsVisibleFirstGroup] = useState(true);
  const [isVisibleSecondGroup, setIsVisibleSecondGroup] = useState(true);

  const onClickExpandFirstGroup = () => {
    setIsVisibleFirstGroup(!isVisibleFirstGroup);
  };

  const onClickExpandSecondGroup = () => {
    setIsVisibleSecondGroup(!isVisibleSecondGroup);
  };

  return (
    <div className="Widget_CT" style={{ marginTop: marginTop ? marginTop : "0px" }}>
      {title && (
        <>
          <div className="Widget_Title_CT">
            <div className="Widget_Title_Left_Area">
              <div className="Widget_Title_Icon">
                <Icon name={icon} size={"24"} color={"#7A7A7A"} />
              </div>
              <div className="Widget_Title_Copy">{title}</div>
            </div>
          </div>
          <div className="Widget_Divider" />
        </>
      )}
      <div className="BarChartGroupWidget_Group_CT">
        <div className="BarChartGroupWidget_Group_Title_CT">
          <div className="BarChartGroupWidget_Group_Title">월 주요 계획</div>
          <button className="BarChartGroupWidget_Group_Button" onClick={onClickExpandFirstGroup}>
            <Icon name={isVisibleFirstGroup ? "ExpandLess" : "ExpandMore"} size={"24"} color={"#7A7A7A"} />
          </button>
        </div>
        <div className="BarChartGroupWidget_Group_Title_Divider" />
        {isVisibleFirstGroup && (
          <>
            <div className="NoteGroupWidget_Body">{data.ShareKeyManagementIssuesMonthly.KeyMonthlyPlan}</div>
          </>
        )}
      </div>
      <div className="BarChartGroupWidget_Group_CT" style={{ marginTop: "0px" }}>
        <div className="BarChartGroupWidget_Group_Title_CT">
          <div className="BarChartGroupWidget_Group_Title">공유 사안</div>
          <button className="BarChartGroupWidget_Group_Button" onClick={onClickExpandSecondGroup}>
            <Icon name={isVisibleSecondGroup ? "ExpandLess" : "ExpandMore"} size={"24"} color={"#7A7A7A"} />
          </button>
        </div>
        <div className="BarChartGroupWidget_Group_Title_Divider" />
        {isVisibleSecondGroup && (
          <>
            {" "}
            <div className="NoteGroupWidget_Body">{data.ShareKeyManagementIssuesMonthly.SharedIssue}</div>
          </>
        )}
      </div>
    </div>
  );
});

export default NoteGroupWidget;
