import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { debounce } from "lodash";
import axios from "../axiosSetting";
import FontFaceObserver from "fontfaceobserver";
import Icon from "../Assets/Icons/Icon";
import Loading from "../Components/Loading";
import InputShort from "../Components/InputShort";
import BasicButton from "../Components/BasicButton";
import PageTitle from "../Components/PageTitle";
import Footer from "../Components/Footer";

const WB21Signin = observer(() => {
  const navigate = useNavigate();

  const [companyIdEmail, setCompanyIdEmail] = useState("");
  const [companyIdEmailNum, setCompanyIdEmailNum] = useState("");
  const [companyRandomNum, setCompanyRandomNum] = useState("");
  const [companyIdEmailNumDone, setCompanyIdEmailNumDone] = useState(false);
  const [isSendIdEmailNum, setIsSendIdEmailNum] = useState(false);
  const [remainingTime, setRemainingTime] = useState(180);
  const [clickEmailValid, setClickEmailValid] = useState(false);

  const [companyPassword, setCompanyPassword] = useState("");
  const [companyPasswordCheck, setCompanyPasswordCheck] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);

  const [companyName, setCompanyName] = useState("");

  const [resistNumber, setResistNumber] = useState("");
  const [resistNumberDone, setResistNumberDone] = useState(false);

  const [lisenceSrc, setLisenceSrc] = useState(null);
  const [sealSrc, setSealSrc] = useState(null);

  const [isMakeSeal, setIsMakeSeal] = useState(false);

  const [sealText, setSealText] = useState("");
  const [sealUrl, setSealUrl] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const canvasRef = useRef(null);
  const downloadLinkRef = useRef(null);

  const onClickMakeSeal = async () => {
    setIsMakeSeal(true);
  };

  const onCloseMakeSeal = async () => {
    setIsMakeSeal(false);
  };

  const generateSeal = () => {
    const specialCharRegex = /[^가-힣a-zA-Z0-9\s]/;
    if (sealText.length > 20) {
      alert("21글자 이상은 입력할 수 없습니다.");
      return;
    } else if (sealText.length === 0) {
      alert("직인에 들어갈 기업/기관명을 입력해주세요.");
      return;
    } else if (specialCharRegex.test(sealText)) {
      alert("특수문자 및 기호는 입력할 수 없습니다.");
      return;
    }

    const loadFonts = async () => {
      const kimjungchul = new FontFaceObserver("KimjungchulMyungjo-Bold");
      const hancom = new FontFaceObserver("HancomHoonminjeongeumH");
      await Promise.all([kimjungchul.load(), hancom.load()]);
    };

    loadFonts()
      .then(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw the outer circle
        ctx.beginPath();
        ctx.arc(150, 150, 140, 0, 2 * Math.PI);
        ctx.strokeStyle = "rgb(203, 25, 25)";
        ctx.lineWidth = 8;
        ctx.stroke();

        // Draw the inner circle
        ctx.beginPath();
        ctx.arc(150, 150, 80, 0, 2 * Math.PI);
        ctx.strokeStyle = "rgb(203, 25, 25)";
        ctx.lineWidth = 8;
        ctx.stroke();

        // Draw text in the inner circle (multiline text)
        ctx.font = "72px KimjungchulMyungjo-Bold"; // Font size and type for inner text
        ctx.fillStyle = "rgb(203, 25, 25)"; // Color for inner text
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText("대표", 150, 126); // First line
        ctx.fillText("이사", 150, 190); // Second line

        // Determine font size for outer text based on sealText length
        let outerFontSize = sealText.length >= 18 ? 42 : sealText.length >= 14 ? 48 : 54;
        ctx.font = `${outerFontSize}px HancomHoonminjeongeumH`; // Set font size

        // Draw the seal text around the outer circle
        const radius = 109;
        const angleStep = (2 * Math.PI) / (sealText.length + 1); // Adjust angle step to include ●

        ctx.fillStyle = "rgb(203, 25, 25)"; // Color for outer text
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        // Add the ● at the beginning of the text
        const fullText = `•${sealText}`;

        for (let i = 0; i < fullText.length; i++) {
          const angle = i * angleStep - Math.PI / 2;
          const x = 150 + radius * Math.cos(angle);
          const y = 150 + radius * Math.sin(angle);

          // Draw small circle at the position where the text starts
          if (fullText[i] === "•") {
            ctx.beginPath();
            ctx.arc(x, y, 8, 0, 2 * Math.PI); // 8px radius for the dot
            ctx.fillStyle = "rgb(203, 25, 25)"; // Same color as the text
            ctx.fill();
          } else {
            ctx.save();
            ctx.translate(x, y);
            ctx.rotate(angle + Math.PI / 2);
            ctx.fillText(fullText[i], 0, 0);
            ctx.restore();
          }
        }

        // Convert canvas to data URL and set sealUrl state
        const dataURL = canvas.toDataURL("image/png");
        setSealUrl(dataURL); // Update state with the data URL
        const downloadLink = downloadLinkRef.current;
        if (downloadLink) {
          downloadLink.href = dataURL;
          downloadLink.style.display = "inline";
        }
      })
      .catch((err) => {
        console.error("Font loading failed:", err);
        alert("서체 로딩에 실패했습니다. 다시 시도해주세요.");
      });
  };

  const handleDownloadSeal = () => {
    if (sealUrl.length > 0) {
      const link = document.createElement("a");
      link.href = sealUrl;
      link.download = `${sealText}_직인.png`;
      link.click();
      setSealText("");
      setSealUrl("");
      onCloseMakeSeal();
    } else {
      alert("직인 이미지를 생성해주세요.");
    }
  };

  const resistNumberData = {
    b_no: [resistNumber],
  };

  const onLicenseUpload = (e) => {
    const file = e.target.files[0];
    const maxSize = 5242880;
    if (file && file.size > maxSize) {
      alert("파일 크기는 최대 5MB를 초과할 수 없습니다.");
      e.target.value = null;
    } else {
      setLisenceSrc(e.target.files[0]);
    }
  };

  const onSealUpload = (e) => {
    const file = e.target.files[0];
    const maxSize = 5242880;
    if (file && file.size > maxSize) {
      alert("파일 크기는 최대 5MB를 초과할 수 없습니다.");
      e.target.value = null;
    } else {
      setSealSrc(e.target.files[0]);
    }
  };

  const onClickSignin = async (e) => {
    if (!companyIdEmailNumDone || !passwordMatch || !companyName || !resistNumberDone || !lisenceSrc || !sealSrc) {
      alert("필수적으로 입력해야할 정보 중 누락된 정보가 있습니다.");
    } else {
      setIsLoading(true);
      e.preventDefault();

      const formData = new FormData();

      formData.append("email", JSON.stringify(companyIdEmail));
      formData.append("password", JSON.stringify(companyPassword));
      formData.append("companyName", JSON.stringify(companyName));
      formData.append("businessRegistrationNumber", JSON.stringify(resistNumber));
      formData.append("seal", sealSrc);
      formData.append("businessRegistration", lisenceSrc);

      try {
        const response = await axios.post("/member/signup", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          transformRequest: [
            function () {
              return formData;
            },
          ],
        });

        if (response.data.status === "가입 성공") {
          alert("회원가입에 성공했습니다! 가입하신 정보로 로그인을 진행해주세요.");
          navigate("/");
          setIsLoading(false);
        } else {
          alert("회원가입에 실패했습니다. 다시 시도해주세요.");
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        alert("회원가입 통신에 실패했습니다. 다시 시도해주세요.");
        console.log("Signin axios Error", error);
      }
    }
  };

  const isEmailValid = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const onSendIdEmailNum = async () => {
    if (isEmailValid(companyIdEmail)) {
      setIsLoading(true);
      setIsSendIdEmailNum(true);
      setClickEmailValid(false);
      try {
        const response = await axios.get("/member/email", {
          params: { email: companyIdEmail },
        });
        setCompanyRandomNum(response.data.code);
        setIsLoading(false);
        alert(`${companyIdEmail} 주소로 인증번호를 발송했습니다.`);
      } catch (error) {
        setIsLoading(false);
        alert(`${companyIdEmail} 주소로 인증번호를 발송을 실패했습니다.\n다시 한번 시도해주세요.`);
        console.log("Send email number error", error);
      }
    } else {
      alert("이메일 주소가 유효하지 않습니다.");
    }
  };

  const debouncedOnSendIdEmailNum = debounce(onSendIdEmailNum, 500);

  const onResendIdEmailNum = () => {
    if (isSendIdEmailNum) {
      setClickEmailValid(false);
      debouncedOnSendIdEmailNum();
      setRemainingTime(180);
    }
  };

  const debouncedOnResendIdEmailNum = debounce(onResendIdEmailNum, 500);

  const onCheckIdEmailNum = () => {
    setClickEmailValid(true);
    if (companyRandomNum === companyIdEmailNum) {
      setCompanyIdEmailNumDone(true);
    } else {
      setCompanyIdEmailNumDone(false);
    }
  };

  const checkResistNumber = async (resistNumberData) => {
    try {
      const response = await fetch(
        "https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=YvOh%2BAHuDPJt2pXE6UF1rahmToz%2BhKPxxls7KxGD7%2B9uaTe%2B%2BFZDy9EywpSbUA%2FtT3TLBChFoxWwlLWqQodeJw%3D%3D",
        {
          method: "POST",
          body: JSON.stringify(resistNumberData),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const onCheckResistNum = async () => {
    const result = await checkResistNumber(resistNumberData);

    if (result.data[0].tax_type !== "국세청에 등록되지 않은 사업자등록번호입니다.") {
      setResistNumberDone(true);
    } else {
      alert(`[${resistNumber}] 는 국세청에 등록되지 않은 사업자등록번호입니다.\n사업자등록번호를 다시 한번 확인해주세요.`);
      setResistNumberDone(false);
    }
  };

  const emailValidComplete = () => {
    return remainingTime > 0 && companyIdEmailNumDone;
  };

  useEffect(() => {
    const validatePassword = (password) => {
      const lengthValid = password.length >= 8 && password.length <= 20;
      const hasLetters = /[a-zA-Z]/.test(password);
      const hasNumbers = /\d/.test(password);
      const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);
      const conditionsMet = [hasLetters, hasNumbers, hasSpecialChars].filter(Boolean).length >= 3;
      return lengthValid && conditionsMet;
    };
    const isPasswordValid = validatePassword(companyPassword);
    setPasswordValid(isPasswordValid);
    if (isPasswordValid && companyPassword === companyPasswordCheck) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }, [companyPassword, companyPasswordCheck, setPasswordMatch, setPasswordValid]);

  useEffect(() => {
    let timerInterval;

    if (!companyIdEmailNumDone && isSendIdEmailNum) {
      timerInterval = setInterval(() => {
        if (remainingTime > 0) {
          setRemainingTime((prevTime) => prevTime - 1);
        }
      }, 1000); // 1초마다 감소
    } else {
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval);
  }, [remainingTime, companyIdEmailNumDone, isSendIdEmailNum, setRemainingTime]);

  return (
    <>
      {isLoading ? <Loading /> : null}
      {isMakeSeal && (
        <div className="CreateSeal_Modal_BG">
          <div className="CreateSeal_Modal_CT">
            <div className="CreateSeal_Title_CT">
              <div className="CreateSeal_Title_Copy">직인 생성</div>
              <button className="CreateSeal_Icon_CT" onClick={onCloseMakeSeal}>
                <Icon name={"Close"} size={"24"} color={"#AAAAAA"} />
              </button>
            </div>
            <div className="CreateSeal_Divider" />
            <div>
              <div className="CreateSeal_Input_CT">
                <input
                  type="text"
                  value={sealText}
                  onChange={(e) => setSealText(e.target.value)}
                  placeholder="직인에 사용될 기업/기관명을 입력해주세요."
                  className="CreateSeal_Input"
                />
                <button onClick={generateSeal} className="CreateSeal_GenerateButton">
                  생성
                </button>
              </div>
              <div className="CreateSeal_Info_Copy">
                기업/기관명은 20자까지 입력할 수 있습니다. (한글, 영문, 숫자만 입력 가능)
                <br />
                ※직인의 서체가 깨진다면 '생성' 버튼을 한번 더 클릭해주세요.
              </div>

              <div className="CreateSeal_Canvas_CT">
                <canvas id="sealCanvas" ref={canvasRef} width="300" height="300" style={{ border: "1px solid rgb(170, 170, 170)" }}></canvas>
              </div>
              <div className="CreateSeal_Info_Copy">직인 다운로드를 하시면 직인 PNG 이미지가 다운로드됩니다.</div>
              <button ref={downloadLinkRef} className="CreateSeal_DownloadButton" onClick={handleDownloadSeal}>
                직인 다운로드
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="Login_Screen_CT " style={{ paddingTop: "36px" }}>
        <div className="Login_Screen_Contents">
          <PageTitle icon={"AddDomain"} title={"회원가입"} />
          <InputShort
            label={"아이디"}
            placeholder={"이메일 형식의 아이디를 입력해주세요."}
            value={companyIdEmail}
            onChange={setCompanyIdEmail}
            required
            requiredCondition={companyIdEmailNumDone}
            buttonLabel={isSendIdEmailNum ? "재발송" : "인증 발송"}
            onClick={isSendIdEmailNum && !companyIdEmailNumDone ? debouncedOnResendIdEmailNum : !isSendIdEmailNum && !companyIdEmailNumDone ? debouncedOnSendIdEmailNum : null}
            name={"email"}
            readOnly={(remainingTime > 0 && companyIdEmailNumDone) || null}
            buttonStatus={companyIdEmailNumDone ? "disabled" : "CTA"}
            marginBottom={"8px"}
          />
          {isSendIdEmailNum && (
            <>
              <InputShort
                placeholder={"인증번호를 입력해주세요."}
                value={companyIdEmailNum}
                onChange={setCompanyIdEmailNum}
                buttonLabel={"확인"}
                onClick={!companyIdEmailNumDone ? onCheckIdEmailNum : null}
                name={"emailValidNumber"}
                readOnly={(remainingTime > 0 && companyIdEmailNumDone) || null}
                buttonStatus={companyIdEmailNumDone ? "disabled" : "CTA"}
                marginBottom={"8px"}
              />
              {remainingTime > 0 ? (
                emailValidComplete() ? (
                  <div className="Input_Feedback_Text_Confirm">인증이 완료되었습니다.</div>
                ) : clickEmailValid ? (
                  <div className="Input_Feedback_Text_Error">
                    {Math.floor(remainingTime / 60)}분 {remainingTime % 60}초 | 인증번호가 일치하지 않습니다.
                  </div>
                ) : (
                  <div className="Input_Feedback_Text_Error">
                    {Math.floor(remainingTime / 60)}분 {remainingTime % 60}초
                  </div>
                )
              ) : (
                <div className="Input_Feedback_Text_Error">인증시간이 만료되었습니다.</div>
              )}
            </>
          )}
          <InputShort
            label={"비밀번호"}
            placeholder={"영문, 숫자, 특수문자를 조합하여 8자 이상 20자 이하"}
            value={companyPassword}
            onChange={setCompanyPassword}
            required
            requiredCondition={passwordMatch}
            name={"password"}
            marginTop={"24px"}
            marginBottom={"8px"}
            type={"password"}
          />
          <InputShort
            placeholder={"비밀번호를 한번 더 입력해주세요."}
            value={companyPasswordCheck}
            onChange={setCompanyPasswordCheck}
            name={"passwordMatch"}
            marginBottom={"8px"}
            type={"password"}
          />
          {passwordMatch ? (
            <div className="Input_Feedback_Text_Confirm">비밀번호가 일치합니다.</div>
          ) : (companyPasswordCheck.length > 0) & !passwordMatch ? (
            <div className="Input_Feedback_Text_Error">비밀번호가 불일치합니다.</div>
          ) : null}
          {!passwordValid && companyPassword.length > 0 && <div className="Input_Feedback_Text_Error">8자 이상 20자 이하 / 영문, 숫자, 특수문자 포함</div>}

          <InputShort
            label={"기업명"}
            placeholder={"사업자등록증에 기재된 기업명을 입력해주세요."}
            value={companyName}
            onChange={setCompanyName}
            required
            requiredCondition={companyName.length > 0}
            name={"companyName"}
            marginTop={"24px"}
          />
          <InputShort
            label={"사업자 등록번호"}
            placeholder={"'-' 하이픈을 제외하고 입력해주세요."}
            value={resistNumber}
            onChange={setResistNumber}
            required
            requiredCondition={resistNumberDone}
            name={"companyName"}
            buttonLabel={"확인"}
            buttonStatus={resistNumberDone ? "disabled" : "CTA"}
            onClick={!resistNumberDone ? onCheckResistNum : null}
            readOnly={resistNumberDone}
            marginBottom={"8px"}
          />
          {resistNumberDone && <div className="Input_Feedback_Text_Confirm">사업자 등록번호가 유효합니다.</div>}

          <InputShort
            label={"사업자 등록증"}
            placeholder={"pdf 파일을 업로드해주세요."}
            value={lisenceSrc?.name || ""}
            onChange={setLisenceSrc}
            required
            requiredCondition={lisenceSrc?.name?.length > 0}
            name={"lisence"}
            buttonLabel={"업로드"}
            onClick={null}
            onClickFile={onLicenseUpload}
            readOnly={true}
            type={"licenseFile"}
            marginTop={"24px"}
          />

          <InputShort
            label={"직인"}
            placeholder={"png 파일을 업로드해주세요."}
            value={sealSrc?.name || ""}
            onChange={setSealSrc}
            required
            requiredCondition={sealSrc?.name?.length > 0}
            name={"seal"}
            buttonLabel={"업로드"}
            onClick={null}
            onClickFile={onSealUpload}
            readOnly={true}
            type={"sealFile"}
            marginBottom={"16px"}
          />
          <button className="Create_Seal_Image_Button" onClick={onClickMakeSeal} style={{ marginBottom: "40px" }}>
            <div className="Create_Seal_Image_Button_Label_CT">
              <div className="Create_Seal_Image_Button_Copy">직인 파일이 없다면 직인 생성을 이용해보세요.</div>
              <div className="Create_Seal_Image_Button_Icon">
                <Icon name={"ArrowForward"} size={"16"} color={"#ffffff"} />
              </div>
            </div>
          </button>
          <form onSubmit={onClickSignin} encType="multipart/form-data">
            <BasicButton label={"회원가입"} type="submit" marginTop={"8px"} marginBottom={"32px"} state={"CTA"} />
          </form>
        </div>
        <Footer />
      </div>
    </>
  );
});

export default WB21Signin;
