import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState } from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";
import { AddDotYYYYMMDD, AddCommaAmountNumber } from "../Utils/ConvertNumberFormat";
import DescriptionModal from "./DescriptionModal";

const ListGroupWidget = observer(({ title, icon, data, unit, marginTop, onClickButton }) => {
  const [isVisibleFirstGroup, setIsVisibleFirstGroup] = useState(true);
  const [isVisibleSecondGroup, setIsVisibleSecondGroup] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitleText, setModalTitleText] = useState();
  const [modalBodyText, setModalBodyText] = useState();

  const onClickExpandFirstGroup = () => {
    setIsVisibleFirstGroup(!isVisibleFirstGroup);
  };

  const onClickExpandSecondGroup = () => {
    setIsVisibleSecondGroup(!isVisibleSecondGroup);
  };

  const onClickOpenModal = (titleText, bodyText) => {
    setModalTitleText(titleText);
    setModalBodyText(bodyText);
    setIsVisibleModal(true);
  };

  const onClickCloseModal = () => {
    setIsVisibleModal(false);
  };

  return (
    <>
      {isVisibleModal && <DescriptionModal title={modalTitleText} body={modalBodyText} onClickClose={onClickCloseModal} />}
      <div className="Widget_CT" style={{ marginTop: marginTop ? marginTop : "0px" }}>
        {title && (
          <>
            <div className="Widget_Title_CT">
              <div className="Widget_Title_Left_Area">
                <div className="Widget_Title_Icon">
                  <Icon name={icon} size={"24"} color={"#7A7A7A"} />
                </div>
                <div className="Widget_Title_Copy">{title}</div>
              </div>
            </div>
            <div className="Widget_Divider" />
          </>
        )}
        <div className="BarChartGroupWidget_Group_CT">
          <div className="BarChartGroupWidget_Group_Title_CT">
            <div className="BarChartGroupWidget_Group_Title">후속 투자</div>
            <button className="BarChartGroupWidget_Group_Button" onClick={onClickExpandFirstGroup}>
              <Icon name={isVisibleFirstGroup ? "ExpandLess" : "ExpandMore"} size={"24"} color={"#7A7A7A"} />
            </button>
          </div>
          <div className="BarChartGroupWidget_Group_Title_Divider" />
          {isVisibleFirstGroup && (
            <>
              <div className="ListWidget_List_CT" style={{ padding: "0px" }}>
                <div className="ListWidget_Label_CT" style={{ marginTop: title ? "16px" : "24px" }}>
                  <div className="List_Label_CompanyName" style={{ marginLeft: "0px", maxWidth: "339px" }}>
                    펀드명(투자사)
                  </div>
                  <div className="List_Label_StockAmount">주식수</div>
                  <div className="List_Label_StockPrice">단가(원)</div>
                  <div className="List_Label_InvestmentMoney">투자금(원)</div>
                  <div className="List_Label_InvestmentMoney">PRE(원)</div>
                  <div className="List_Label_InvestmentMoney">POST(원)</div>
                  <div className="List_Label_InvestmentPhase">투자 단계</div>
                  <div className="List_Label_Date" style={{ maxWidth: "99px", minWidth: "99px" }}>
                    날짜
                  </div>
                </div>
                <div className="List_Label_Divider" />

                {data.MonthlyFollowUpInvestmentsAndSubsidies.FollowUpInvestments.map((listItem) => (
                  <React.Fragment key={listItem.ID}>
                    <div className="ListWidget_Body_CT">
                      <div className="List_Body_CompanyName" style={{ marginLeft: "0px", maxWidth: "339px" }}>
                        {listItem?.FundName ? listItem.FundName : "-"}
                      </div>
                      <div className="List_Body_StockAmount">{listItem?.StockAmount ? `${AddCommaAmountNumber(listItem.StockAmount)}` : "-"}</div>
                      <div className="List_Body_StockPrice">{listItem?.StockPrice ? `${AddCommaAmountNumber(listItem.StockPrice)}` : "-"}</div>
                      <div className="List_Body_InvestmentMoney">{listItem?.InvestmentMoney ? `${AddCommaAmountNumber(listItem.InvestmentMoney)}` : "-"}</div>
                      <div className="List_Body_InvestmentMoney">{listItem?.PreInvestmentMoney ? `${AddCommaAmountNumber(listItem.PreInvestmentMoney)}` : "-"}</div>
                      <div className="List_Body_InvestmentMoney">{listItem?.PostInvestmentMoney ? `${AddCommaAmountNumber(listItem.PostInvestmentMoney)}` : "-"}</div>
                      <div className="List_Body_InvestmentPhase">{listItem?.InvestmentPhase ? `${AddCommaAmountNumber(listItem.InvestmentPhase)}` : "-"}</div>
                      <div className="List_Body_Date">{listItem?.InvestmentDate ? `${AddDotYYYYMMDD(listItem.InvestmentDate)}` : "-"}</div>
                      <button className="List_Body_Detail_Button" onClick={() => onClickOpenModal(listItem.FundName, listItem.Note)}>
                        비고
                      </button>
                    </div>
                    <div className="List_Body_Divider" />
                  </React.Fragment>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="BarChartGroupWidget_Group_CT" style={{ marginTop: "0px" }}>
          <div className="BarChartGroupWidget_Group_Title_CT">
            <div className="BarChartGroupWidget_Group_Title">지원금</div>
            <button className="BarChartGroupWidget_Group_Button" onClick={onClickExpandSecondGroup}>
              <Icon name={isVisibleSecondGroup ? "ExpandLess" : "ExpandMore"} size={"24"} color={"#7A7A7A"} />
            </button>
          </div>
          <div className="BarChartGroupWidget_Group_Title_Divider" />
          {isVisibleSecondGroup && (
            <>
              <div className="ListWidget_List_CT" style={{ padding: "0px" }}>
                <div className="ListWidget_Label_CT" style={{ marginTop: title ? "16px" : "24px" }}>
                  <div className="List_Label_CompanyName" style={{ marginLeft: "0px", maxWidth: "434px" }}>
                    주관 기관
                  </div>
                  <div className="List_Label_CompanyName" style={{ marginLeft: "0px", maxWidth: "433px" }}>
                    프로그램명
                  </div>
                  <div className="List_Label_InvestmentMoney">지원금(원)</div>
                  <div className="List_Label_Date" style={{ maxWidth: "99px", minWidth: "99px" }}>
                    날짜
                  </div>
                </div>
                <div className="List_Label_Divider" />

                {data.MonthlyFollowUpInvestmentsAndSubsidies.Subsidies.map((listItem) => (
                  <React.Fragment key={listItem.ID}>
                    <div className="ListWidget_Body_CT">
                      <div className="List_Body_CompanyName" style={{ marginLeft: "0px", maxWidth: "434px" }}>
                        {listItem?.HostOrganizationName ? listItem.HostOrganizationName : "-"}
                      </div>
                      <div className="List_Body_CompanyName" style={{ marginLeft: "0px", maxWidth: "433px" }}>
                        {listItem?.ProgramName ? listItem.ProgramName : "-"}
                      </div>
                      <div className="List_Body_InvestmentMoney">{listItem?.SupportFund ? `${AddCommaAmountNumber(listItem.SupportFund)}` : "-"}</div>
                      <div className="List_Body_Date">{listItem?.SupportDate ? `${AddDotYYYYMMDD(listItem.SupportDate)}` : "-"}</div>
                      <button className="List_Body_Detail_Button" onClick={() => onClickOpenModal(listItem.HostOrganizationName, listItem.Note)}>
                        비고
                      </button>
                    </div>
                    <div className="List_Body_Divider" />
                  </React.Fragment>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default ListGroupWidget;
