import axios from "axios";

// 기본 URL 설정
axios.defaults.baseURL = "https://t19yskdet3.execute-api.ap-northeast-2.amazonaws.com/product";

// 기본 설정 추가 (선택 사항)
axios.defaults.withCredentials = true; // 자격 증명 포함 설정
axios.defaults.headers.common["Content-Type"] = "application/json"; // 기본 Content-Type 설정

export default axios;
