import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
// import PageTitle from "../Components/PageTitle";
import Icon from "../Assets/Icons/Icon";
import DescriptionWidget from "../Components/DescriptionWidget";
import BarChartGroupWidget from "../Components/BarChartGroupWidget";
import BreakdownGroupWidget from "../Components/BreakdownGroupWidget";
import ListGroupWidget from "../Components/ListGroupWidget";
import NoteGroupWidget from "../Components/NoteGroupWidget";

const WB62InvestorCompanyShareholderReportDetail = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state.item;
  const companyData = location.state.companyData;
  const investeeData = location.state.investeeData;
  const investeeDetailItem = location.state.investeeDetailItem;
  const onClickPDF = () => {
    window.open(item.Url, "_blank", "noopener noreferrer");
  };
  const [newMembershipRegistrationArray, setNewMembershipRegistrationArray] = useState([{ label: "", value: 0 }]);
  const [newPaidMemberArray, setNewPaidMemberArray] = useState([{ label: "", value: 0 }]);
  const [accumulatedPaidMemberArray, setAccumulatedPaidMemberArray] = useState([{ label: "", value: 0 }]);
  const [breakawayCustomerArray, setBreakawayCustomerArray] = useState([{ label: "", value: 0 }]);
  const [monthlyRecurringRevenueArray, setMonthlyRecurringRevenueArray] = useState([{ label: "", value: 0 }]);
  const [churnMonthlyRecurringRevenueArray, setChurnMonthlyRecurringRevenueArray] = useState([{ label: "", value: 0 }]);
  const [netMonthlyRecurringRevenueArray, setNetMonthlyRecurringRevenueArray] = useState([{ label: "", value: 0 }]);

  useEffect(() => {
    if (investeeDetailItem) {
      const itemYearMonth = parseInt(item.ReportYearMonth, 10);
      const topSixReports = investeeDetailItem.shareholderReportList
        .filter((report) => parseInt(report.ReportYearMonth, 10) <= itemYearMonth) // 조건에 맞는 보고서만 필터링
        .sort((a, b) => Number(b.ReportYearMonth) - Number(a.ReportYearMonth)) // ReportYearMonth 기준으로 정렬
        .slice(0, 6)
        .sort((a, b) => Number(a.ReportYearMonth) - Number(b.ReportYearMonth));

      const newMembershipRegistrationResult = topSixReports.map((report) => ({
        label: `${report.ReportYearMonth.slice(4, 6)}월`,
        value: report.MonthlyCustomerIndicators.MembershipIndicators.NewMembershipRegistration,
      }));
      const newPaidMemberResult = topSixReports.map((report) => ({
        label: `${report.ReportYearMonth.slice(4, 6)}월`,
        value: report.MonthlyCustomerIndicators.MembershipIndicators.NewPaidMember,
      }));
      const accumulatedPaidMemberResult = topSixReports.map((report) => ({
        label: `${report.ReportYearMonth.slice(4, 6)}월`,
        value: report.MonthlyCustomerIndicators.MembershipIndicators.AccumulatedPaidMember,
      }));
      const breakawayCustomerResult = topSixReports.map((report) => ({
        label: `${report.ReportYearMonth.slice(4, 6)}월`,
        value: report.MonthlyCustomerIndicators.EarningsIndicators.BreakawayCustomer,
      }));
      const monthlyRecurringRevenueResult = topSixReports.map((report) => ({
        label: `${report.ReportYearMonth.slice(4, 6)}월`,
        value: report.MonthlyCustomerIndicators.EarningsIndicators.MonthlyRecurringRevenue,
      }));
      const churnMonthlyRecurringRevenueResult = topSixReports.map((report) => ({
        label: `${report.ReportYearMonth.slice(4, 6)}월`,
        value: report.MonthlyCustomerIndicators.EarningsIndicators.ChurnMonthlyRecurringRevenue,
      }));
      const netMonthlyRecurringRevenueResult = topSixReports.map((report) => ({
        label: `${report.ReportYearMonth.slice(4, 6)}월`,
        value: report.MonthlyCustomerIndicators.EarningsIndicators.NetMonthlyRecurringRevenue,
      }));
      setNewMembershipRegistrationArray(newMembershipRegistrationResult);
      setNewPaidMemberArray(newPaidMemberResult);
      setAccumulatedPaidMemberArray(accumulatedPaidMemberResult);
      setBreakawayCustomerArray(breakawayCustomerResult);
      setMonthlyRecurringRevenueArray(monthlyRecurringRevenueResult);
      setChurnMonthlyRecurringRevenueArray(churnMonthlyRecurringRevenueResult);
      setNetMonthlyRecurringRevenueArray(netMonthlyRecurringRevenueResult);
    }
  }, [investeeDetailItem, item]);

  const onClickArrowBack = () => {
    navigate(`/InvestorCompanyDetail/${companyData.ID}`, {
      state: {
        item: companyData,
        investeeData: investeeData,
        investeeDetailItemCopy: investeeDetailItem,
      },
    });
  };

  const data = {
    MonthlyCustomerIndicators: {
      MembershipIndicators: {
        NewMembershipRegistration: newMembershipRegistrationArray,
        NewPaidMember: newPaidMemberArray,
        AccumulatedPaidMember: accumulatedPaidMemberArray,
      },
      EarningsIndicators: {
        BreakawayCustomer: breakawayCustomerArray,
        MonthlyRecurringRevenue: monthlyRecurringRevenueArray,
        ChurnMonthlyRecurringRevenue: churnMonthlyRecurringRevenueArray,
        NetMonthlyRecurringRevenue: netMonthlyRecurringRevenueArray,
      },
    },
  };

  return (
    <div className="Screen_CT">
      <div className="Contents_Area_Left_Margin" />
      <div className="Contents_Area_CT">
        {/* <PageTitle icon={"ArrowBack"} title={`${item.ReportName}`} onClick={onClickArrowBack} /> */}
        <div className="PageTitle_CT" style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
            <button className="PageTitle_Icon_Button" onClick={onClickArrowBack}>
              <Icon name={"ArrowBack"} size={"32"} color={"#7A7A7A"} />
            </button>
            <div className="PageTitle_Title">{item.ReportName}</div>
          </div>
          <button className="Report_PDF_Button" onClick={onClickPDF}>
            PDF
          </button>
        </div>
        <DescriptionWidget
          icon={"Domain"}
          title={companyData.InvesteeName}
          descriptionLabel={["산업", "기업구분", "자본금", "대표자", "설립일", "직원수", "입사자", "퇴사자", "투자단계", "투자액", "평균연봉", "주소"]}
          item={companyData}
          subItem={investeeDetailItem}
          type={"investeeProfile"}
        />
        <BarChartGroupWidget title={"월간 고객지표"} icon={"GroupPerson"} marginTop={"24px"} data={data} />
        <BreakdownGroupWidget title={"월간 현금흐름 현황"} icon={"Bill"} marginTop={"24px"} data={item} />
        <ListGroupWidget title={"월간 후속투자 및 지원금"} icon={"Money"} marginTop={"24px"} data={item} />
        <NoteGroupWidget title={"월간 주요 경영 현안 공유"} icon={"EventNote"} marginTop={"24px"} data={item} />
      </div>
      <div className="Contents_Area_Right_Margin" />
    </div>
  );
});

export default WB62InvestorCompanyShareholderReportDetail;
