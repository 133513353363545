import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import axios from "../axiosSetting";
import Loading from "../Components/Loading";
import PageTitle from "../Components/PageTitle";
import DescriptionWidget from "../Components/DescriptionWidget";
import GraphWidget from "../Components/GraphWidget";
import TabMenuListWidget from "../Components/TabMenuListWidget";

const WB61InvestorCompanyDetail = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const investeeData = location.state.investeeData;
  const investeeDetailItemCopy = location.state.investeeDetailItemCopy;
  console.log("investeeData ", investeeData);
  const [isLoading, setIsLoading] = useState(false);
  const [investeeDetailItem, setInvesteeDetailItem] = useState(investeeDetailItemCopy || null);

  useEffect(() => {
    const getInvesteeItem = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get("/investee/detail", {
          params: {
            investeeEmail: investeeData.Email,
          },
        });
        console.log("피투자사 상세 조회", response);
        if (response.data.status === "기업 상세정보 확인 성공") {
          setInvesteeDetailItem(response.data.data);
        } else {
          alert("피투자사 상세 정보 불러오기를 실패했습니다.\n새로고침을 실행해주세요.");
        }
      } catch (error) {
        alert("피투자사 상세 정보 불러오기 통신을 실패했습니다.\n로그아웃 후 다시 로그인해주세요.");
        console.log("Load Investee List Axios Error : ", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (!investeeDetailItem) {
      getInvesteeItem();
    }
  }, [investeeData, investeeDetailItem]);

  const onClickArrowBack = () => {
    navigate("/investorCompanyList");
  };

  // const onClickTitleButton = () => {
  //   console.log("click");
  // };

  const onClickListItem = (documentItem, companyData) => {
    navigate(`/investorCompanyShareholderReportDetail/${documentItem.ID}`, {
      state: {
        item: documentItem,
        companyData: companyData,
        investeeData: investeeData,
        investeeDetailItem: investeeDetailItem,
      },
    });
  };

  const getLargestTotalBalance = (subItem) => {
    if (!subItem || !Array.isArray(subItem.shareholderReportList) || subItem.shareholderReportList.length === 0) {
      return null;
    }

    const { shareholderReportList } = subItem;

    const largestReport = shareholderReportList.reduce((maxReport, currentReport) => {
      const currentReportYearMonth = parseInt(currentReport.ReportYearMonth, 10);

      if (!maxReport) {
        return currentReport;
      }
      const maxReportYearMonth = parseInt(maxReport.ReportYearMonth, 10);
      return currentReportYearMonth > maxReportYearMonth ? currentReport : maxReport;
    }, null);

    return largestReport?.MonthlyCashFlow?.MonthlyBankBookBalance?.TotalBalance || null;
  };

  const getLargestTotalIncome = (subItem) => {
    if (!subItem || !Array.isArray(subItem.shareholderReportList) || subItem.shareholderReportList.length === 0) {
      return null;
    }

    const { shareholderReportList } = subItem;
    const largestReport = shareholderReportList.reduce((maxReport, currentReport) => {
      const currentReportYearMonth = parseInt(currentReport.ReportYearMonth, 10);

      if (!maxReport) {
        return currentReport;
      }
      const maxReportYearMonth = parseInt(maxReport.ReportYearMonth, 10);
      return currentReportYearMonth > maxReportYearMonth ? currentReport : maxReport;
    }, null);

    return largestReport?.MonthlyCashFlow?.MonthlyIncome?.TotalIncome || null;
  };

  const getLargestTotalSpending = (subItem) => {
    if (!subItem || !Array.isArray(subItem.shareholderReportList) || subItem.shareholderReportList.length === 0) {
      return null;
    }

    const { shareholderReportList } = subItem;
    const largestReport = shareholderReportList.reduce((maxReport, currentReport) => {
      const currentReportYearMonth = parseInt(currentReport.ReportYearMonth, 10);
      if (!maxReport) {
        return currentReport;
      }
      const maxReportYearMonth = parseInt(maxReport.ReportYearMonth, 10);
      return currentReportYearMonth > maxReportYearMonth ? currentReport : maxReport;
    }, null);

    return largestReport?.MonthlyCashFlow?.MonthlySpending?.TotalSpending || null;
  };

  const calculateBurnRate = (income, spending) => {
    if (spending > income) {
      const burnRate = Math.abs(spending - income);
      return burnRate;
    } else {
      return 0;
    }
  };

  const donutChartGraphData = investeeDetailItem
    ? {
        wholeData: getLargestTotalBalance(investeeDetailItem),
        partData: calculateBurnRate(getLargestTotalIncome(investeeDetailItem), getLargestTotalSpending(investeeDetailItem)),
      }
    : { wholeData: null, partData: null };

  const sortInvesteeDetailItemData = investeeDetailItem ? investeeDetailItem : [];

  const sortedReportList = (sortInvesteeDetailItemData?.shareholderReportList || []).sort((a, b) => {
    const yearMonthA = parseInt(a.ReportYearMonth, 10);
    const yearMonthB = parseInt(b.ReportYearMonth, 10);
    if (yearMonthA !== yearMonthB) {
      return yearMonthB - yearMonthA;
    }
    return new Date(a.CreationDate) - new Date(b.CreationDate);
  });

  return (
    <>
      {isLoading ? <Loading /> : null}
      {!isLoading && investeeDetailItem && (
        <div className="Screen_CT">
          <div className="Contents_Area_Left_Margin" />
          <div className="Contents_Area_CT">
            <PageTitle icon={"ArrowBack"} title={"기업상세 정보"} onClick={onClickArrowBack} />
            <DescriptionWidget
              icon={"Domain"}
              title={investeeData.CompanyName}
              // button={"업데이트"}
              leftButtonIcon={"Update"}
              // onClickButton={onClickTitleButton}
              descriptionLabel={["산업", "기업구분", "자본금", "대표자", "설립일", "직원수", "입사자", "퇴사자", "투자단계", "투자액", "평균연봉", "주소"]}
              item={investeeData}
              subItem={investeeDetailItem}
              type={"investeeProfile"}
            />
            {investeeDetailItem?.shareholderReportList?.length > 0 ? (
              <div className="GraphWidget_CT">
                <GraphWidget icon={"DonutChart"} title={"런웨이"} width={"1/3"} graphData={donutChartGraphData} />
                <GraphWidget icon={"BarChart"} title={"번 레이트"} width={"1/3"} graphData={investeeDetailItem} />
                <GraphWidget icon={"Money"} title={"자본금"} width={"1/3"} graphData={investeeDetailItem} />
              </div>
            ) : null}

            <TabMenuListWidget tabList={["주주보고서"]} marginTop={"24px"} firstDataList={sortedReportList || []} onClickListItem={onClickListItem} companyData={investeeData} />
          </div>
          <div className="Contents_Area_Right_Margin" />
        </div>
      )}
    </>
  );
});

export default WB61InvestorCompanyDetail;
