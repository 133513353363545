import "../GlobalStyle.css";
import "../PCStyle.css";
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";
import DonutChartComponent from "./DonutChartComponent";
import BarChartComponent from "./BarChartComponent";
import LineChartComponent from "./LineChartComponent";
import { ConvertToKoreanCurrency } from "../Utils/ConvertNumberFormat";

const GraphWidget = observer(({ icon, title, button, leftButtonIcon, rightButtonIcon, onClickButton, marginTop, width, graphData }) => {
  const [burnRateArray, setBurnRateArray] = useState();
  const [totalBalanceArray, setTotalBalanceArray] = useState();
  useEffect(() => {
    if (graphData && graphData.shareholderReportList) {
      const transformedData = Array.isArray(graphData.shareholderReportList)
        ? graphData.shareholderReportList.map((report) => {
            const reportYearMonth = report.ReportYearMonth; // 문자열 형태
            const totalBalance = parseInt(report.MonthlyCashFlow.MonthlyBankBookBalance.TotalBalance);
            const totalIncome = parseInt(report.MonthlyCashFlow.MonthlyIncome.TotalIncome);
            const totalSpending = parseInt(report.MonthlyCashFlow.MonthlySpending.TotalSpending);

            const calculateBurnRate = (income, spending) => {
              if (spending > income) {
                const burnRate = spending - income;
                return burnRate;
              } else {
                return 0;
              }
            };

            return {
              reportYearMonth,
              netIncome: calculateBurnRate(totalIncome, totalSpending),
              totalBalance: totalBalance,
            };
          })
        : [];
      const topSixReports = transformedData
        .sort((a, b) => Number(b.reportYearMonth) - Number(a.reportYearMonth))
        .slice(0, 6) // 상위 6개 추출
        .sort((a, b) => Number(a.reportYearMonth) - Number(b.reportYearMonth));
      const burnRateResult = topSixReports.map((report) => ({
        label: `${report.reportYearMonth.slice(4, 6)}월`,
        value: report.netIncome,
      }));
      const totalBalanceResult = topSixReports.map((report) => ({
        label: `${report.reportYearMonth.slice(4, 6)}월`,
        value: report.totalBalance,
      }));
      setTotalBalanceArray(totalBalanceResult);
      setBurnRateArray(burnRateResult);
    }
  }, [graphData]);

  const getLargestTotalBalance = (subItem) => {
    if (!subItem || !Array.isArray(subItem.shareholderReportList) || subItem.shareholderReportList.length === 0) {
      return null;
    }
    const { shareholderReportList } = subItem;
    const largestReport = shareholderReportList.reduce((maxReport, currentReport) => {
      const currentReportYearMonth = parseInt(currentReport.ReportYearMonth, 10);
      if (!maxReport) {
        return currentReport;
      }
      const maxReportYearMonth = parseInt(maxReport.ReportYearMonth, 10);
      return currentReportYearMonth > maxReportYearMonth ? currentReport : maxReport;
    });

    return largestReport?.MonthlyCashFlow?.MonthlyBankBookBalance?.TotalBalance || null;
  };

  const getLargestTotalIncome = (subItem) => {
    if (!subItem || !Array.isArray(subItem.shareholderReportList) || subItem.shareholderReportList.length === 0) {
      return null;
    }
    const { shareholderReportList } = subItem;
    const largestReport = shareholderReportList.reduce((maxReport, currentReport) => {
      const currentReportYearMonth = parseInt(currentReport.ReportYearMonth, 10);
      if (!maxReport) {
        return currentReport;
      }
      const maxReportYearMonth = parseInt(maxReport.ReportYearMonth, 10);
      return currentReportYearMonth > maxReportYearMonth ? currentReport : maxReport;
    });

    return largestReport?.MonthlyCashFlow?.MonthlyIncome?.TotalIncome || null;
  };

  const getLargestTotalSpending = (subItem) => {
    if (!subItem || !Array.isArray(subItem.shareholderReportList) || subItem.shareholderReportList.length === 0) {
      return null;
    }

    const { shareholderReportList } = subItem;
    const largestReport = shareholderReportList.reduce((maxReport, currentReport) => {
      const currentReportYearMonth = parseInt(currentReport.ReportYearMonth, 10);
      if (!maxReport) {
        return currentReport;
      }
      const maxReportYearMonth = parseInt(maxReport.ReportYearMonth, 10);
      return currentReportYearMonth > maxReportYearMonth ? currentReport : maxReport;
    });

    return largestReport?.MonthlyCashFlow?.MonthlySpending?.TotalSpending || null;
  };

  const calculateBurnRate = (income, spending) => {
    if (spending > income) {
      const burnRate = spending - income;
      return burnRate;
    } else {
      return 0;
    }
  };
  const burnRate = calculateBurnRate(getLargestTotalIncome(graphData), getLargestTotalSpending(graphData));

  const dummyData = [
    { label: "--월", value: 0 },
    { label: "--월", value: 0 },
    { label: "--월", value: 0 },
    { label: "--월", value: 0 },
    { label: "--월", value: 0 },
    { label: "--월", value: 0 },
  ];

  const getNextHigherUnit = (data) => {
    const maxValue = Math.max(...data.map((item) => item.value));
    const exponent = Math.floor(Math.log10(maxValue));
    const nextUnit = Math.pow(10, exponent + 1);
    const maxValueStr = maxValue.toString();
    if (
      maxValueStr[0] === "1" &&
      maxValueStr
        .slice(1)
        .split("")
        .every((char) => char === "0")
    ) {
      return maxValue;
    } else {
      return nextUnit;
    }
  };

  const calculateStartupRunwayMonth = (capital, monthlyExpenses) => {
    if (monthlyExpenses === 0) {
      return "자본금 증가 상태";
    }
    const runwayMonths = Math.floor(capital / monthlyExpenses);
    return `${runwayMonths}개월`;
  };

  const calculateNetIncome = (income, spending) => {
    if (spending > income) {
      return 0;
    } else {
      const netIncome = income - spending;
      return netIncome;
    }
  };

  const netIncome = calculateNetIncome(getLargestTotalIncome(graphData), getLargestTotalSpending(graphData));

  return (
    <div className={width === "1/3" ? "Widget_OneThird_CT" : "Widget_CT"} style={{ marginTop: marginTop ? marginTop : "0px" }}>
      {title && (
        <>
          <div className="Widget_Title_CT">
            <div className="Widget_Title_Left_Area">
              <div className="Widget_Title_Icon">
                <Icon name={icon} size={"24"} color={"#7A7A7A"} />
              </div>
              <div className="Widget_Title_Copy">{title}</div>
            </div>
            {button && (
              <div className="Widget_Title_Right_Area">
                <button className="Widget_Button_CT" onClick={onClickButton}>
                  {leftButtonIcon && (
                    <div className="Widget_Button_Left_Icon">
                      <Icon name={leftButtonIcon} size={"16"} color={"#AAAAAA"} />
                    </div>
                  )}
                  <div className="Widget_Button_Label">{button}</div>
                  {rightButtonIcon && (
                    <div className="Widget_Button_Right_Icon">
                      <Icon name={rightButtonIcon} size={"16"} color={"#AAAAAA"} />
                    </div>
                  )}
                </button>
              </div>
            )}
          </div>
          <div className="Widget_Divider" />
        </>
      )}
      {}
      {icon === "DonutChart" && (
        <div className="GraphWidget_Data_CT">
          <div className="GraphWidget_Data_Title">
            <div className="GraphWidget_Data_Title_Copy">{calculateStartupRunwayMonth(graphData.wholeData, graphData.partData)}</div>
            <div className="GraphWidget_Data_Title_Badge">Gross Burn Rate 기준</div>
          </div>
          <div className="GraphWidget_Data_Title_Divider" />
          <div className="GraphWidget_Data_Graph">
            <DonutChartComponent size={143} strokeWidth={8} total={graphData.wholeData} part={graphData.partData} color="#e7efff" netIncome={netIncome} />
          </div>
        </div>
      )}
      {graphData?.shareholderReportList?.length > 0 ? (
        <>
          {icon === "BarChart" && (
            <div className="GraphWidget_Data_CT">
              <div className="GraphWidget_Data_Title">
                {burnRate > 0 ? (
                  <div className="GraphWidget_Data_Title_Copy">{ConvertToKoreanCurrency(burnRate, "원")} / 월</div>
                ) : (
                  <div className="GraphWidget_Data_Title_Copy">자본금 증가</div>
                )}
              </div>
              <div className="GraphWidget_Data_Title_Divider" />

              <BarChartComponent data={burnRateArray || dummyData} maxValue={getNextHigherUnit(burnRateArray || dummyData)} unit={"원"} />
            </div>
          )}
        </>
      ) : null}
      {graphData?.shareholderReportList?.length > 0 ? (
        <>
          {icon === "Money" && (
            <div className="GraphWidget_Data_CT">
              <div className="GraphWidget_Data_Title">
                <div className="GraphWidget_Data_Title_Copy">{ConvertToKoreanCurrency(getLargestTotalBalance(graphData), "원")}</div>
              </div>
              <div className="GraphWidget_Data_Title_Divider" />
              <LineChartComponent data={totalBalanceArray || dummyData} maxValue={getNextHigherUnit(totalBalanceArray || dummyData)} />
            </div>
          )}
        </>
      ) : null}
    </div>
  );
});

export default GraphWidget;
