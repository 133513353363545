import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
// import { useNavigate } from "react-router-dom";
// import { observer } from "mobx-react";
// import { loadTossPayments } from "@tosspayments/payment-sdk";
// import { v4 as uuidv4 } from "uuid";
import PageTitle from "../Components/PageTitle";
// import DescriptionModal from "../Components/DescriptionModal";
// import DescriptionWidget from "../Components/DescriptionWidget";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import Icon from "../Assets/Icons/Icon";
const WB71InvestmentCompanyProfile = () => {
  // const navigate = useNavigate();
  // const [isVisibleSubscribeCancelModal, setIsVisibleSubscribeCancelModal] = useState(false);
  const userData = LoadComapanyData("userData");
  // const investorData = {
  //   ID: uuidv4(),
  //   InvestorEmail: "root@gdpstudio.io",
  //   InvestorName: "주식회사 지디피스튜디오",
  //   InvestorIndustry: "통신 및 방송 장비 제조업",
  //   InvestorType: "중소기업",
  //   InvestorCapital: "88888888888888",
  //   InvestorRepresentative: "김나눔",
  //   InvestorEstablishDate: "20240102",
  //   InvestorEmployeesNumber: "888888",
  //   InvestorJoinEmployees: "8888",
  //   InvestorResignerEmployees: "8888",
  //   InvestorAverageAnnualSalary: "8888888888",
  //   InvestorAddress: "서울특별시 영등포구 여의대로 128 (여의도동)",
  //   InvestorDetailAddress: "태광빌딩 10층",
  // };

  // const paymentData = {
  //   ID: uuidv4(),
  //   PaymentStatus: "구독중",
  //   PaymentDate: "20240102",
  //   ExpirationDate: "20240102",
  //   PaymentCardType: "현대카드",
  //   PaymentCardNumber: "1234-****-****-**56",
  // };

  // const onClickTitleButton = () => {
  //   console.log("click");
  // };

  // const onClickProfleEdit = () => {
  //   navigate("/investmentCompanyProfileEdit", {
  //     state: {
  //       item: investorData,
  //     },
  //   });
  // };

  // const onClickOpenSubscribeCancelModal = () => {
  //   setIsVisibleSubscribeCancelModal(true);
  // };

  // const onClickCloseSubscribeCancelModal = () => {
  //   setIsVisibleSubscribeCancelModal(false);
  // };

  // const testCustomerKey = uuidv4();

  // const handlePayment = () => {
  //   console.log("payment");
  //   const successUrl = `${window.location.origin}/paymentConfirm`;
  //   const failUrl = `${window.location.origin}/paymentFail`;

  //   loadTossPayments(process.env.REACT_APP_TOSSPAYMENTS_CLIENT_KEY).then((tossPayments) => {
  //     tossPayments
  //       .requestBillingAuth("카드", {
  //         // amount: 1000000,
  //         // orderId: nanoid(),
  //         // orderName: "써티드 기업회원 연간 이용료",
  //         // customerEmail: companyEmail,
  //         // customerName: companyName,
  //         customerKey: testCustomerKey,
  //         successUrl: successUrl,
  //         failUrl: failUrl,
  //       })
  //       // .then((data) => {
  //       //   console.log("data : ", data);
  //       // })

  //       .catch(function (error) {
  //         console.log("Payments error", error);
  //         if (error.code === "USER_CANCEL") {
  //           // 결제 고객이 결제창을 닫았을 때 에러 처리
  //         } else if (error.code === "INVALID_CARD_COMPANY") {
  //           alert("카드 정보가 유효하지 않습니다.");
  //         }
  //       });
  //   });
  // };

  return (
    <>
      {/* {isVisibleSubscribeCancelModal && (
        <DescriptionModal
          title={"구독 취소"}
          body={"구독을 취소하시면 이런 점이 안좋습니다. 구독을 취소 하시겠습니까?"}
          onClickClose={onClickCloseSubscribeCancelModal}
          label={"확인"}
          onClick={onClickTitleButton}
        />
      )} */}
      <div className="Screen_CT">
        <div className="Contents_Area_Left_Margin" />
        <div className="Contents_Area_CT">
          <PageTitle icon={"Profile"} title={"프로필"} />
          {/* <DescriptionWidget
            icon={"Domain"}
            title={userData.companyName}
            button={"수정"}
            leftButtonIcon={"EditSquare"}
            onClickButton={onClickProfleEdit}
            descriptionLabel={["산업", "기업구분", "자본금", "대표자", "설립일", "직원수", "입사자", "퇴사자", "평균연봉", "주소"]}
            item={investorData}
            type={"investorProfile"}
          /> */}
          <div className="Widget_CT" style={{ marginTop: "0px" }}>
            <>
              <div className="Widget_Title_CT">
                <div className="Widget_Title_Left_Area">
                  <div className="Widget_Title_Icon">
                    <Icon name={"Domain"} size={"20"} color={"#7A7A7A"} />
                  </div>
                  <div className="Widget_Title_Copy">{userData.companyName}</div>
                </div>
              </div>
              <div className="Widget_Divider" />
            </>

            <div className="DescriptionWidget_Data_CT">
              <div className="DescriptionWidget_Data_Left">
                <div className="DescriptionWidget_Data_Item">
                  <div className="DescriptionWidget_Data_Label">이메일</div>
                  <div className="DescriptionWidget_Data_Body">{userData.email || "-"}</div>
                </div>
              </div>
            </div>
          </div>
          {/* <DescriptionWidget
            icon={"CreditCard"}
            title={"결제 정보"}
            button={"결제 수단 변경"}
            leftButtonIcon={"CreditCard"}
            onClickButton={handlePayment}
            secondButton={"구독 취소"}
            leftSecondButtonIcon={"CancelCircle"}
            onClickSecondButton={onClickOpenSubscribeCancelModal}
            descriptionLabel={["상태", "결제일", "만료일", "결제카드", "카드번호"]}
            item={paymentData}
            type={"paymentInfomation"}
            marginTop={"24px"}
            paymentFunction={handlePayment}
          /> */}
        </div>
        <div className="Contents_Area_Right_Margin" />
      </div>
    </>
  );
};

export default WB71InvestmentCompanyProfile;
