import "../GlobalStyle.css";
import "../PCStyle.css";
import React from "react";
import { ConvertToKoreanCurrency } from "../Utils/ConvertNumberFormat";

const DonutChartComponent = ({ size, strokeWidth, total, part, color, netIncome }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const percentage = (part / total) * 100;
  const offset = circumference - (percentage / 100) * circumference;

  const calculateStartupRunway = (capital, monthlyExpenses) => {
    if (monthlyExpenses === 0) {
      return "자본금 증가";
    }
    const currentDate = new Date(); // 현재 날짜
    const runwayMonths = Math.floor(capital / monthlyExpenses);
    currentDate.setMonth(currentDate.getMonth() + runwayMonths);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    return `${year}년 ${month}월`;
  };

  return (
    <>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <div style={{ position: "relative", width: size, height: size }}>
          <svg width={size} height={size}>
            <circle cx={size / 2} cy={size / 2} r={radius} stroke="#94BAFD" strokeWidth={strokeWidth} fill="transparent" />
            <circle
              cx={size / 2}
              cy={size / 2}
              r={radius}
              stroke={color}
              strokeWidth={strokeWidth}
              fill="transparent"
              strokeDasharray={circumference}
              strokeDashoffset={offset}
              strokeLinecap="butt"
              style={{ transition: "stroke-dashoffset 0.35s" }}
              transform={`rotate(-90 ${size / 2} ${size / 2})`}
            />
          </svg>
          <div className="DonutChart_Inner_Data">
            <div className="DonutChart_Inner_Data_Badge">Cash Zero</div>
            <div className="DonutChart_Inner_Data_Text">{calculateStartupRunway(total, part)}</div>
          </div>
        </div>
      </div>
      <div className="GraphWidget_Data_Label_CT">
        <div className="GraphWidget_Data_Label_Item" style={{ marginBottom: "12px" }}>
          <div className="GraphWidget_Data_Label_Box" style={{ backgroundColor: "#94BAFD" }} />
          <div className="DonutChart_Data_Label_Title">자본금</div>
          <div className="DonutChart_Data_Label_Vertical_Divider" />
          <div className="DonutChart_Data_Label_Body">{ConvertToKoreanCurrency(total, "원")}</div>
        </div>
        <div className="GraphWidget_Data_Label_Item">
          <div
            className="GraphWidget_Data_Label_Box"
            style={{ backgroundColor: "#e7efff", width: "12px", height: "12px", borderStyle: "solid", borderWidth: "1px", borderColor: "#DBDBDB" }}
          />
          <div className="DonutChart_Data_Label_Title">{netIncome > 0 ? "순수익" : "번 레이트"}</div>
          <div className="DonutChart_Data_Label_Vertical_Divider" />
          <div className="DonutChart_Data_Label_Body">{netIncome > 0 ? ConvertToKoreanCurrency(netIncome, "원") : ConvertToKoreanCurrency(part, "원")}</div>
        </div>
      </div>
    </>
  );
};

export default DonutChartComponent;
