import "./GlobalStyle.css";
import "./PCStyle.css";
import "../src/index.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { observer } from "mobx-react";
import GNB from "./Components/GNB";
import PaymentConfirm from "./Pages/PaymentConfirm";
import PaymentFail from "./Pages/PaymentFail";
import WB11Login from "./Pages/WB11Login";
import WB21Signin from "./Pages/WB21Signin";
import WB31Home from "./Pages/WB31Home";
import WB41InviteInvestorCompany from "./Pages/WB41InviteInvestorCompany";
import WB51InvestorCompanyList from "./Pages/WB51InvestorCompanyList";
import WB61InvestorCompanyDetail from "./Pages/WB61InvestorCompanyDetail";
import WB62InvestorCompanyShareholderReportDetail from "./Pages/WB62InvestorCompanyShareholderReportDetail";
import WB71InvestmentCompanyProfile from "./Pages/WB71InvestmentCompanyProfile";
import WB72InvestmentCompanyProfileEdit from "./Pages/WB72InvestmentCompanyProfileEdit";

const App = observer(() => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WB11Login />} />
        <Route path="/signin" element={<WB21Signin />} />
        <Route path="/paymentConfirm" element={<PaymentConfirm />} />
        <Route path="/paymentFail" element={<PaymentFail />} />
        <Route
          path="/*"
          element={
            <>
              <GNB />
              <Routes>
                <Route path="/home" element={<WB31Home />} />
                <Route path="/inviteInvestorCompany" element={<WB41InviteInvestorCompany />} />
                <Route path="/investorCompanyList" element={<WB51InvestorCompanyList />} />
                <Route path="/investorCompanyDetail/:id" element={<WB61InvestorCompanyDetail />} />
                <Route path="/investorCompanyShareholderReportDetail/:id" element={<WB62InvestorCompanyShareholderReportDetail />} />
                <Route path="/investmentCompanyProfile" element={<WB71InvestmentCompanyProfile />} />
                <Route path="/investmentCompanyProfileEdit" element={<WB72InvestmentCompanyProfileEdit />} />
              </Routes>
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
});

export default App;
